import { AppThunkResult, AppThunkDispatch } from "./index";
import { IAppContext } from "../../contexts/app/AppContext";
import { IGetState } from "../reducers";
import { getCollection } from "../../laco-common/base/firestore-utils";
import { IEvent } from "../../laco-common/base/models/event";
import { IGroup } from "../../laco-common/base/models/group";

const COLLECTION: string = "messages";

export enum EventActionTypes {
	LOAD_EVENTS = "[event] LOAD_EVENTS",
	LOAD_HOME_EVENTS = "[event] LOAD_HOME_EVENTS",
	LOAD_GROUPS = "[event] LOAD_GROUPS",
}

export const loadEvents =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		let events: IEvent[] = await getCollection<IEvent>(
			appContext.firebase.clientFirebase,
			COLLECTION,
			"eventDate",
			(query) => query.where("active", "==", true),
			true
		);
		events = (events || []).filter((e: IEvent) => !!e.eventDate && e.eventDate !== "TBD");
		dispatch({
			type: EventActionTypes.LOAD_EVENTS,
			payload: events,
		});
		return events;
	};

export const loadHomePageEvents =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		let events: IEvent[] = await getCollection<IEvent>(
			appContext.firebase.clientFirebase,
			COLLECTION,
			"eventDate",
			(query) => query.where("active", "==", true),
			false,
			Date.now() - 86400000
		);
		events = (events || [])
			.filter((e: IEvent) => !!e.eventDate && e.eventDate !== "TBD")
			.filter((e, i: number) => i <= 3);
		dispatch({
			type: EventActionTypes.LOAD_HOME_EVENTS,
			payload: events,
		});
		return events;
	};

export const loadGroups =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		let groups: IGroup[] = await getCollection<IGroup>(appContext.firebase.clientFirebase, "groups");
		dispatch({
			type: EventActionTypes.LOAD_GROUPS,
			payload: groups,
		});
		return groups;
	};

export const mapEventDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadEvents: () => dispatch(loadEvents()),
	loadHomePageEvents: () => dispatch(loadHomePageEvents()),
	loadGroups: () => dispatch(loadGroups()),
});

export type IEventActionDispatches = ReturnType<typeof mapEventDispatchToProps>;
