import { WidgetConfig, WidgetType } from "./widget";
import { CMSPermission } from "./user";
import { IBaseEntity } from ".";

export const ROOT_PAGE: string = "root";
export type RootPage = typeof ROOT_PAGE;

// TODO: updated by and on
export type IPage = IBaseEntity & {
	title: string;
	headerWidget?: WidgetType;
	navTitle?: string;
	hidePageTitle?: boolean;
	titleClass?: string;
	metaTitle: string;
	headline?: string;
	parentPage: RootPage | string;
	order: number;
	path?: string;
	permanent?: boolean;
	formId?: string | string[];
	formIconUrl?: string;
	headerImage?: string;
	widgets?: WidgetConfig[];
	requiresAuth?: boolean;
	requiresMembership?: boolean;
	redirectTo?: string;
	content?: string;
	content2?: string;
	noPadding?: boolean;
	omitContentPaddingTop?: boolean;
	omitContentPaddingBottom?: boolean;
	donateUrl?: string;
	galleryId?: string;
	texts?: { [key: string]: string };
	fullWidth?: boolean;
};

export type ISitePage = IPage & {
	pages?: IPage[];
};

export type ICMSPage = ISitePage & {
	collection?: string;
	subCollections?: string[];
	canAdd?: boolean;
	isEditable?: boolean;
	isDeletable?: boolean;
	permission?: CMSPermission;
	editPermission?: CMSPermission;
	deletePermission?: CMSPermission;
	useFirebaseAuthApp?: boolean;
	omitCreatedCol?: boolean;
	tooltipFields?: string[];
	filterQuery?: string;
	sortMode?: SortMode;
	sortDirection?: SortDirection;
	sortByField?: string;
	singleItemName?: string;
};

export const SORT_MODE_CONST = ["Alphabetical", "Updated", "Created", "Order", "Date Field"] as const;
type SortModeTuple = typeof SORT_MODE_CONST;
export type SortMode = SortModeTuple[number];

export const SORT_DIRECTION_CONST = ["asc", "desc"] as const;
type SortDirectionTuple = typeof SORT_DIRECTION_CONST;
export type SortDirection = SortDirectionTuple[number];
