import React, { useState, useEffect, useContext } from "react";
import { TextField, BaseTextFieldProps } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { DynamicFormFieldProps } from "../DynamicForm";
// import { DynamicFormField, SelectOption } from "../form-validation/dynamic";
import {
	NumberFormatPercentage,
	NumberFormatComma,
	NumberFormatInt,
	NumberFormatPhone,
	onPercentageInput,
	PERCENTAGE_MAX_LENGTH,
} from "../formatters";
import { FormLabel } from ".";
import { UIComboBox } from "./FormControls";
import { DynamicFormField, SelectOption } from "../../laco-common/base/form-validation/dynamic";
import { isEmpty } from "../../utils";
import { IAuthContext } from "../../laco-common/base/auth";
import AuthContext from "../../laco-common/auth/AuthContext";
import { ISelectFormField } from "../../laco-common";
import { getCollectionSelectOptions } from "../../laco-common/base/form-validation/utils";
import { IFirebaseContext } from "../../laco-common/base/firebase";
import { FirebaseContext } from "../../laco-common/firebase/context";

export const TextSelectInput: React.FC<DynamicFormFieldProps & Partial<BaseTextFieldProps>> = (
	props: DynamicFormFieldProps & Partial<BaseTextFieldProps>
) => {
	const authContext: IAuthContext = useContext(AuthContext);
	const firebaseContext: IFirebaseContext = useContext(FirebaseContext);
	const field: DynamicFormField = props.field;
	const [value, setValue] = useState<any>(!isEmpty(field.value) ? field.value : field.defaultValue);
	const [refresh, setRefresh] = useState<number>(0);
	const [selectOptions, setSelectOptions] = useState<SelectOption[]>(field.selectOptions || []);
	const { index, onChange, onBlur, processing, getHelperText, omitLabel, variant, skipAutoFocus } = props;
	const inputRef = React.useRef();

	console.debug("TextSelectInput field:", field);
	const selectFormField = field as any as ISelectFormField;
	if (selectFormField.collectionName && !(Array.isArray(selectFormField.selectValues) || field.getSelectOptions)) {
		field.getSelectOptions = getCollectionSelectOptions(selectFormField, undefined, firebaseContext);
	}

	useEffect(() => {
		if (field.getSelectOptions) {
			const form = props.getForm ? props.getForm() : null;
			field.getSelectOptions(authContext, form).then((options: SelectOption[]) => {
				setSelectOptions(options);
				if (field.autoSelectSingleValue && options.length === 1) {
					onChange(options[0].value);
				} else if (options.find((option: SelectOption) => option.value === value)) {
					onChange(value);
				}
			});
		} else {
			if (field.autoSelectSingleValue && selectOptions.length === 1) {
				onChange(selectOptions[0].value);
			}
			if (Array.isArray(selectFormField.selectValues)) {
				setSelectOptions(
					selectFormField.selectValues.map((value: string | SelectOption): SelectOption => {
						if (typeof value === "object") return value;
						return { label: value, value };
					})
				);
			}
		}
		let timeout;
		if (!skipAutoFocus && field.autoFocus) {
			timeout = setTimeout(() => {
				if (inputRef.current) (inputRef.current as any).focus();
			}, 100);
		}
		return () => {
			if (field.autoFocus) {
				clearTimeout(timeout);
			}
		};
	}, []);

	useEffect(() => {
		if (props.field.value !== value) {
			setValue(props.field.value || field.defaultValue);
			setRefresh(refresh + 1);
		}
	}, [props.field.value, props.field.valid]);

	const disabled: boolean = field.disabled || processing || (field.disabledFn && field.disabledFn(props.getForm()));

	// 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
	const getInputMode = () => {
		switch (field.fieldType) {
			case "email":
				return "email";
			case "phone":
				return "tel";
			case "link":
				return "url";
			case "number":
				return "numeric";
			case "text":
			case "textarea":
				return "text";
			default: {
				if (field.email) return "email";
			}
		}
	};

	if (field.fieldType === "autocomplete" || field.fieldType === "collection" || field.fieldType === "focusRow") {
		return (
			<UIComboBox
				className={props.className || "w-100"}
				options={selectOptions}
				label={omitLabel ? undefined : <FormLabel field={field} getForm={props.getForm} />}
				onSelect={(option) => {
					let val;
					if (option && typeof option === "object") {
						val = option.value || "";
					} else {
						val = option;
					}
					setValue(val);
					onChange(val);
				}}
				onBlur={onBlur}
				disabled={disabled}
				freeSolo={field.freeSolo}
				openText={field.label ? `Open ${field.label}` : undefined}
				selectedOption={
					value && selectOptions && selectOptions.length
						? selectOptions.find((s: SelectOption) => s.value === value) || { value, label: value }
						: undefined
				}
				inputProps={{
					name: field.key,
					id: field.key,
					helperText: getHelperText ? getHelperText(field) : undefined,
					required: field.mandatory && (!field.optionalWhen || !!field.optionalWhen(field, props.getForm())),
					error: props.error || (field.touched && !field.valid),
					placeholder: field.placeholder,
				}}
			/>
		);
	}

	return (
		<>
			{!omitLabel && <FormLabel field={field} />}
			<TextField
				key={refresh}
				variant="outlined"
				select={field.fieldType === "boolean" || field.fieldType === "select" || field.fieldType === "yes/no"}
				autoComplete={field.autoComplete}
				// label={omitLabel ? undefined : <FormLabel field={field} getForm={props.getForm} />}
				autoFocus={!skipAutoFocus && (field.autoFocus || index === 0)}
				placeholder={field.placeholder}
				required={field.mandatory && (!field.optionalWhen || !!field.optionalWhen(field, props.getForm()))}
				// required={field.mandatory}
				inputMode={getInputMode()}
				type={getInputMode()}
				className={"w-100 " + (props.className || "")}
				multiline={field.multiline || field.fieldType === "textarea"}
				// defaultValue={_value}
				value={value}
				onInput={field.isPercentage ? onPercentageInput : undefined}
				// onChange={onChange}
				onChange={(e) => {
					setValue(e.target.value);
					if (field.fieldType === "boolean" || field.fieldType === "select") {
						onChange(e);
					}
				}}
				onBlur={onChange}
				error={props.error || (field.touched && !field.valid)}
				helperText={getHelperText ? getHelperText(field) : undefined}
				disabled={disabled}
				spellCheck={!!field.multiline}
				inputRef={inputRef}
				inputProps={{
					name: field.key,
					id: field.key,
					maxLength: field.isPercentage ? field.maxLength || PERCENTAGE_MAX_LENGTH - 2 : field.maxLength,
					minLength: field.minLength,
					min: field.min,
					max: field.max,
					spellCheck: !!field.multiline,
					onChange: (e: any) => {
						setValue(e.target.value);
						if (
							field.isPhoneNo ||
							field.fieldType === "phone" ||
							field.isPercentage ||
							field.isInteger ||
							field.fieldType === "number"
						) {
							onChange(e.target.value);
						}
					},
					// maxFractionDigits: field.maxFractionDigits,
				}}
				InputProps={
					field.isPhoneNo || field.fieldType === "phone"
						? { inputComponent: NumberFormatPhone, onChange }
						: field.isPercentage
						? { inputComponent: NumberFormatPercentage, onChange }
						: field.isInteger
						? { inputComponent: NumberFormatInt, onChange }
						: field.fieldType === "number"
						? { inputComponent: NumberFormatComma, onChange }
						: { spellCheck: !!field.multiline }
				}
			>
				{!!selectOptions &&
					!!selectOptions.length &&
					selectOptions.map((option: SelectOption, i: number) => {
						return (
							<MenuItem
								key={i}
								value={option.value}
								// selected={_value === option.value}
								style={{ minHeight: "28px" }}
							>
								{option.label}
							</MenuItem>
						);
					})}
			</TextField>
		</>
	);
};

export default TextSelectInput;
