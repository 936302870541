import { IAppContext } from "../../contexts/app/AppContext";
import { IAction, AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapAppStateToProps } from "../reducers/app";
import { IPage, ROOT_PAGE, ISitePage } from "../../laco-common/base/models/page";
import { getCollection, getDocument, addUpdateDocumentInCollection } from "../../laco-common/base/firestore-utils";
import { IFirebaseValueMap } from "../../models/firebase/index";
import { ISiteTextContent, ISiteQuotesContent, ISiteCustomWidget } from "../../models/cms";
import { IClientFirebase } from "../../laco-common/base/firebase";

export type IPagesPayload = {
	pages: IPage[];
	navigation: ISitePage[];
};

export enum AppActionTypes {
	LOAD_PAGES = "[app] LOAD_PAGES",
	LOAD_SITE_TXT_CONTENT = "[app] LOAD_SITE_TXT_CONTENT",
	LOAD_SITE_QUOTES = "[app] LOAD_SITE_QUOTES",
	LOAD_SITE_CUSTOM_WIDGETS = "[app] LOAD_SITE_CUSTOM_WIDGETS",
	SET_CURRENT_PAGE = "[app] SET_CURRENT_PAGE",
	LOAD_SITE_CONFIGURATION = "[app] LOAD_SITE_CONFIGURATION",
	CREATE_CUSTOM_WIDGET = "[app] CREATE_CUSTOM_WIDGET",
}

export const loadPages = () => async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
	return loadPagesData(
		appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
		"site_pages",
		dispatch,
		AppActionTypes.LOAD_PAGES
	).then((res: IPagesPayload) => {
		if (res && res?.pages?.length && getState().appStore.activePage) {
			const active = res.pages.find((p) => p.id === getState().appStore.activePage.id);
			if (active) {
				// setCurrentPage(active, true);
				const action: IAction = {
					type: AppActionTypes.SET_CURRENT_PAGE,
					payload: active,
				};
				dispatch(action);
			}
		}
		return res;
	});
};

export const loadPagesData = (
	clientFirebase: IClientFirebase,
	collectionName: string,
	dispatch,
	actionType,
	prefixPath: string = ""
): Promise<IPagesPayload> => {
	return new Promise<any>(async (resolve, reject) => {
		try {
			let pages: IPage[] = await getCollection<IPage>(
				clientFirebase,
				collectionName,
				"order"
				// (query) => query.where("active", "==", true)
			);
			if (prefixPath) {
				pages = pages.map((p: IPage) => {
					return { ...p, path: p.path ? `${prefixPath}${p.path}` : undefined };
				});
			}
			const rootPage: ISitePage = pages.find((p: IPage) => p.id === ROOT_PAGE);
			const navigation: ISitePage[] = pages.filter((p: IPage) => p.parentPage === ROOT_PAGE);
			navigation.forEach((rootPage: ISitePage) => {
				rootPage.pages = pages.filter((p: IPage) => p.parentPage === rootPage.id);
			});
			const payload: IPagesPayload = { pages, navigation };
			const action: IAction = {
				type: actionType,
				payload: { pages, navigation, headerImage: rootPage.headerImage },
			};
			dispatch(action);
			resolve(payload);
			return payload;
		} catch (err) {
			return reject(null);
		}
	});
};

export const loadTextContent =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const textContentList: ISiteTextContent[] = await getCollection<ISiteTextContent>(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			"site_text_content",
			"order",
			(query) => query.where("active", "==", true)
		);
		dispatch({
			type: AppActionTypes.LOAD_SITE_TXT_CONTENT,
			payload: textContentList,
		});
		return textContentList;
	};

export const loadQuotes =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const quotes: ISiteQuotesContent[] = await getCollection<ISiteQuotesContent>(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			"site_quotes",
			undefined,
			(query) => query.where("active", "==", true)
		);
		dispatch({
			type: AppActionTypes.LOAD_SITE_QUOTES,
			payload: quotes,
		});
		return quotes;
	};

export const loadCustomWidgets =
	(loadInactive?: boolean): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		let customWidgets: ISiteCustomWidget[] = await getCollection<ISiteCustomWidget>(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			"site_custom_widgets",
			undefined,
			loadInactive ? undefined : (query) => query.where("active", "==", true)
		);
		customWidgets = customWidgets.map((c: ISiteCustomWidget) => {
			return { ...c, id: c.id?.trim() };
		});
		dispatch({
			type: AppActionTypes.LOAD_SITE_CUSTOM_WIDGETS,
			payload: customWidgets,
		});
		return customWidgets;
	};

export const createCustomWidget =
	(id: string, widgetData?: ISiteCustomWidget): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		let data: any = widgetData || {};
		delete data.id;
		const widget = await addUpdateDocumentInCollection(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			"site_custom_widgets",
			data,
			id
		);
		await dispatch(loadCustomWidgets());
		dispatch({
			type: AppActionTypes.CREATE_CUSTOM_WIDGET,
			payload: widget,
		});
		return widget;
	};

export const setCurrentPage =
	(page: IPage, forceUpdate?: boolean, mergePageProps?: boolean, mergeId?: string) =>
	(dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const activePage: IPage = getState().appStore.activePage;
		if (page && activePage && page.id === activePage.id && !forceUpdate) return;

		let pageData: IPage = page;
		if (mergePageProps) {
			const id: string = mergeId || page.id;
			const pageDBData: IPage = getState().appStore.pages.find((p: IPage) => p.id === id);
			pageData = { ...page, ...pageDBData };
			if (mergeId) {
				pageData = { ...page, ...pageDBData };
			} else {
				pageData = { ...pageDBData, ...page };
			}

			console.debug("mergePageProps pageData:", pageData);
		}

		const action: IAction = {
			type: AppActionTypes.SET_CURRENT_PAGE,
			payload: pageData,
		};
		dispatch(action);
		return page;
	};

export const loadSiteConfiguration =
	() => async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const siteConfiguration: IFirebaseValueMap = await getDocument<IFirebaseValueMap>(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			"site_settings",
			"config"
		);
		dispatch({
			type: AppActionTypes.LOAD_SITE_CONFIGURATION,
			payload: siteConfiguration,
		});
		return siteConfiguration;
	};

export const mapAppDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadPages: () => dispatch(loadPages()),
	loadTextContent: () => dispatch(loadTextContent()),
	loadQuotes: () => dispatch(loadQuotes()),
	loadCustomWidgets: (loadInactive?: boolean) => dispatch(loadCustomWidgets(loadInactive)),
	createCustomWidget: (id: string, widgetData?: ISiteCustomWidget) => dispatch(createCustomWidget(id, widgetData)),
	setCurrentPage: (page: IPage, forceUpdate?: boolean, mergePageProps?: boolean, mergeId?: string) =>
		dispatch(setCurrentPage(page, forceUpdate, mergePageProps, mergeId)),
	loadSiteConfiguration: () => dispatch(loadSiteConfiguration()),
});

export type IAppActionDispatches = ReturnType<typeof mapAppDispatchToProps>;
export type IAppStoreProps = ReturnType<typeof mapAppStateToProps> & ReturnType<typeof mapAppDispatchToProps>;
