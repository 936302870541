export enum LOCAL_STORAGE_KEYS {
	DEBUG_MODE = "site-debug-mode",
	DEBUG_MODE_STRINGIFY = "site-debug-mode-stringify",
	MOCK_APIS = "site-mock-apis",
	OFFLINE_MODE = "site-offline-mode",
	CMS_MENU_OPEN = "cms-menu-open",
	STORE_FORM_ID = "site-ecwid-form-id",
	ANONYMOUS_USER_EMAIL = "site-user-email",
}

export const REDUX_STORE_STATE = "site-store-state" + process.env.REACT_APP_VERSION;
