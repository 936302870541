import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as firebase from "firebase/app";
import "firebase/auth";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";
import { PlatformContext } from "./laco-common/platform/context";
import { renderWebHyperLink } from "./utils/platform";
import firebaseConfig from "./laco-common/firebase/web/firebase";
import AppContext, { IAppContext } from "./contexts/app/AppContext";
import AuthProvider from "./laco-common/auth/AuthProvider";
import AppProvider from "./contexts/app/AppProvider";
import ClientFirebase from "./laco-common/firebase/web";
// import APIProvider from "./contexts/api/APIProvider";
import { createAppStore } from "./redux";
import { renderFormInputTextSelect, renderFormInputPhone, renderFormInputAddress } from "./forms/InputComponents";
import AuthContext from "./laco-common/auth/AuthContext";
import { IAuthContext } from "./laco-common/base/auth";
import { IUserProfileData, IUserAuthPayload } from "./laco-common/base/models/user";
import AppStore from "./components/ecwid/StoreContext";
import "./styles/index.scss";

const Loader = (
	<div style={{ width: "100%", height: "100%", textAlign: "center" }}>
		<CircularProgress style={{ margin: "25vh auto", color: process.env.REACT_APP_PRIMARY_COLOR as string }} />
	</div>
);

const BUILD_TARGETS = [
	{
		name: "site",
		path: "./components/App",
	},
	{
		name: "ps",
		path: "./components/PSApp",
	},
];

const buildConfig = BUILD_TARGETS.find(({ name }) => process.env.REACT_APP_BUILD_TARGET === name) || BUILD_TARGETS[0];
const path = buildConfig.path;

const firebaseAuthApp = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app); // import { getAnalytics } from "firebase/analytics";
const TENANT: string = process.env.REACT_APP_TENANT;
ClientFirebase.init(TENANT);

const validTenants: Set<string> = new Set();
validTenants.add(TENANT);
// const secondTenant: string = process.env.REACT_APP_SITE_TENANT;
// if (secondTenant) {
// 	validTenants.add(secondTenant);
// }

console.log("TENANT, buildConfig:", TENANT, buildConfig);

// TODO: dana can we authenticate with more than 1 firebase? Listen for auth state on either?

// AuthProvider expected initialized firebase instance. fine
// There will be areas in the application (like a user area) that it makes sense to wrap with a Provider
// we already made redux auth though so I suggest a parent component that is bound to redux and can pass those props in if there is such an area
// for single components you can use connectCustom
// only issue is FirebaseContext isn't updated but in areas that require Auth, you may reprovide the context in the same parent. That would bridge that gap.

ReactDOM.render(Loader, document.getElementById("root"));

async function loadUserTenantFirebase(payload: IUserAuthPayload) {
	if (!payload?.profile?.tenant) return null;
	const tenantFirebase = await ClientFirebase.initAdditional(payload.profile.tenant, true);
	return Promise.resolve(tenantFirebase);
}

import(`${path}`).then(({ default: AppContainer }) => {
	console.log("index.tsx path loaded:", path);
	return ReactDOM.render(
		<MuiThemeProvider theme={theme}>
			<PlatformContext.Provider
				value={{
					renderHyperLink: renderWebHyperLink,
					renderFormInputTextSelect,
					renderFormInputPhone,
					renderFormInputAddress,
					initStore: AppStore.initStore,
					goToPayment: AppStore.goToPayment,
					goToEcwidPayment: AppStore.goToEcwidPayment,
				}}
			>
				<AppProvider>
					<AppContext.Consumer>
						{(appContext: IAppContext) => {
							const store = createAppStore(appContext);
							console.log("store created, appContext:", store, appContext);
							return (
								<Provider store={store}>
									<AuthProvider
										firebase={firebaseAuthApp}
										allowedTenants={validTenants}
										allowSubscribers={true}
										loadUserTenantFirebase={loadUserTenantFirebase}
									>
										<AuthContext.Consumer>
											{(authContext: IAuthContext) => (
												<AppContainer store={store} authContext={authContext} />
											)}
										</AuthContext.Consumer>
									</AuthProvider>
								</Provider>
							);
						}}
					</AppContext.Consumer>
				</AppProvider>
			</PlatformContext.Provider>
		</MuiThemeProvider>,
		document.getElementById("root")
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
