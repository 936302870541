import { IPSAgent } from "./psAgent";
import { IAuthContext } from "../auth";
import { IClientFirebase } from "../firebase";

export type PSRole = "guest" | "member" | "agent" | "admin";

export const getPSRole = (
	authContext: Partial<IAuthContext>,
	clientFirebase: IClientFirebase,
	agents?: IPSAgent[]
): PSRole => {
	console.log("getPSRole clientFirebase:", clientFirebase);
	if (!authContext.isAuthenticated || !clientFirebase?.hasPeerSupport) return "guest";
	if (authContext.userPayload.role === "subscriber") return "guest";
	if (authContext.userPayload.psAdmin || authContext.userPayload.superAdmin) return "admin";
	if (agents && agents.length && agents.find((a: IPSAgent) => a.uid === authContext.uid)) return "agent";
	return "member";
};
