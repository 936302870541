import { IBaseEntity } from ".";
import { Address } from "./address";

export type IPSAgent = IBaseEntity & {
	email: string;
	first_name: string;
	last_name: string;
	mobile_number: string;
	homeAddress: Address;
	showHomeZipCodeOnly?: "Yes" | "No";
	workAddress: Address;
	showWorkZipCodeOnly?: "Yes" | "No";
	title?: string;
	bio?: string;
	uid?: string;
	role?: "Member" | "Admin" | "Coordinator";
	peerTeam?: string;
};

export const getAgentLink = (agent: IPSAgent): string => `/ps/peers/${agent.id}`;
