import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, Store } from "redux";
import { createStateSyncMiddleware, withReduxStateSync, Config } from "redux-state-sync";
import thunk from "redux-thunk";
import { IState, getInitialStoreState, reducers, saveState } from "./reducers";
import { IAction } from "./actions";
import { IAppContext } from "../contexts/app/AppContext";
import { DEBUG_MODE } from "../utils/developer";
import { SubscriptionActionTypes } from "./actions/subscription";

// TODO: set up simpler store
// can remove the custom middleware, just past the context in.
// need firebase context as well - don't have to though, as you could call it directly. But long term prob best to go through the same api
// dunno yet how requests will look

// store events for calendar
// need a simple get and to bind the calendar component
// TODO: login?

const logger = createLogger({
	level: "log",
	collapsed: true,
	diff: true,
});

const syncTabStateConfig: Config = {
	channel: "site-broadcast-channel",
	// whitelist: [SubscriptionActionTypes.ACTIVATE_SUBSCRIPTION, SubscriptionActionTypes.LOAD_SUBSCRIPTION],
};

export function createAppStore(appContext: IAppContext): Store {
	const thunkMiddleware = thunk.withExtraArgument(appContext);
	const middleware = DEBUG_MODE
		? applyMiddleware(thunkMiddleware, logger, createStateSyncMiddleware(syncTabStateConfig))
		: applyMiddleware(thunkMiddleware, createStateSyncMiddleware(syncTabStateConfig));
	const initialStoreState: IState = getInitialStoreState(appContext);
	const store: Store = createStore<IState, IAction, any, any>(reducers, initialStoreState, middleware);
	store.subscribe(() => {
		console.debug("STORE UPDATED", store.getState());
		// saveState(store.getState(), appContext);
		const storeState: IState = store.getState();
		const { appStore, eventStore, formStore, streamStore, stripeStore } = storeState;
		saveState(
			{
				appStore,
				eventStore,
				formStore,
				streamStore,
				stripeStore,
			},
			appContext
		);
	});
	return store;
}
