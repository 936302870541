import { IAppContext } from "../../contexts/app/AppContext";
import { AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapGalleryStateToProps } from "../reducers/gallery";
import { getCollection } from "../../laco-common/base/firestore-utils";
import { ISiteGalleryContent } from "../../models/cms";

export enum GalleryActionTypes {
	LOAD_GALLERIES = "[gallery] LOAD_GALLERIES",
}

export const loadGalleries = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const galleries: ISiteGalleryContent[] = await getCollection<ISiteGalleryContent>(
		appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
		"site_galleries",
		undefined,
		(query) => query.where("active", "==", true)
	);
	dispatch({
		type: GalleryActionTypes.LOAD_GALLERIES,
		payload: galleries,
	});
	return galleries;
};

export const mapGalleryDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadGalleries: () => dispatch(loadGalleries()),
});

export type IGalleryActionDispatches = ReturnType<typeof mapGalleryDispatchToProps>;
export type IGalleryStoreProps = ReturnType<typeof mapGalleryStateToProps> &
	ReturnType<typeof mapGalleryDispatchToProps>;
