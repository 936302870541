import { IUserAuthPayload, IUserProfileData } from "../laco-common/base/models/user";
import { convertDateFromFirebase, compareDates } from "../utils/date-utils";

export enum BWMembershipStatus {
	ACTIVE = "active",
	EXPIRED = "expired",
	INACTIVE = "inactive",
}

export type IMembershipDetails = {
	status: BWMembershipStatus;
	isSubscriber: boolean;
	updatedOn?: Date;
	validThrough?: Date;
};

export const getMembershipDetails = (authPayload: IUserAuthPayload): IMembershipDetails => {
	return {
		status: getBWMembershipStatus(authPayload.profile),
		isSubscriber: authPayload.role === "subscriber",
		updatedOn: convertDateFromFirebase(authPayload.profile.subscriptionUpdatedOn),
		validThrough: convertDateFromFirebase(authPayload.profile.subscriptionEndDate),
	};
};

export const getBWMembershipStatus = (profile: IUserProfileData): BWMembershipStatus => {
	let isValidSubscriptionEndDate: boolean = getValidSubscriptionEndDate(profile);
	if (profile.isBW_member && isValidSubscriptionEndDate) return BWMembershipStatus.ACTIVE;
	if (profile.isBW_member && !isValidSubscriptionEndDate) return BWMembershipStatus.EXPIRED;
	return BWMembershipStatus.INACTIVE;
};

export const getValidSubscriptionEndDate = (profile: IUserProfileData): boolean => {
	if (!profile.subscriptionEndDate) return true; // no end date
	// there is a date, verify that it's in the future (or last day)
	const endDate: Date = convertDateFromFirebase(profile.subscriptionEndDate);
	const tomorrow: Date = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	const comparison = compareDates(endDate, tomorrow);
	console.log("getValidSubscriptionEndDate() comparison:", comparison);
	if (comparison === ">" || comparison === "=") return true;
	return false;
};

// subscription end date
// set to end of following day. fair
// TODO: set subscriptionEndDate to be + 1 day
