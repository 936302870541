import { IAppContext } from "../../contexts/app/AppContext";
import { v4 as uuidv4 } from "uuid";
import { AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapStripeStateToProps, IStripeState } from "../reducers/stripe";
import { addUpdateDocumentInCollection, getCollection, getDocument } from "../../laco-common/base/firestore-utils";
import { getUpdateProps } from "./peerSupport";
import { OrderItem, OrderDetails } from "../../laco-common/base/models/order";
import { IFormSubmission } from "../../laco-common/base/models/formSubmission";
import { asyncForEach } from "../../laco-common/base/utils";

export enum StripeActionTypes {
	ADD_TO_CART = "[stripe] ADD_TO_CART",
	SET_EMAIL = "[stripe] SET_EMAIL",
	DELETE_FROM_CART = "[stripe] DELETE_FROM_CART",
	LOAD_ORDERS = "[stripe] LOAD_ORDERS",
	CLEAR_CART = "[stripe] CLEAR_CART",
	COMPLETE_PAYMENT = "[stripe] COMPLETE_PAYMENT",
	CLEAR_COMPLETED_ORDER = "[stripe] CLEAR_COMPLETED_ORDER",
}

export const addToCart =
	(item: OrderItem): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		let id: string = item.id;
		if (!id) {
			id = uuidv4();
		}
		dispatch({
			type: StripeActionTypes.ADD_TO_CART,
			payload: { ...item, id },
		});
		return item;
	};

export const setEmail =
	(email: string): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		dispatch({
			type: StripeActionTypes.SET_EMAIL,
			payload: email,
		});
	};

export const deleteFromCart =
	(cookieOrder: OrderItem): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		dispatch({
			type: StripeActionTypes.DELETE_FROM_CART,
			payload: cookieOrder,
		});
		return cookieOrder;
	};

export const clearCart =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		return dispatch({
			type: StripeActionTypes.CLEAR_CART,
			payload: null,
		});
	};

export const clearCompletedOrder =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		return dispatch({
			type: StripeActionTypes.CLEAR_COMPLETED_ORDER,
			payload: null,
		});
	};

export const getCartSummary =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		let total: number = 0;
		let name: string = "";
		let description: string = "";
		getState().stripeStore.cartItems.forEach((item: OrderItem) => {
			total += item.price * item.amount;
			name += `, ${item.name}`;
			description += `, ${item.description}`;
		});
		name = (name || " ,").substring(2);
		description = (description || " ,").substring(2);
		return {
			total,
			name,
			description,
		};
	};

export const onCompletePayment =
	(orderDetails: OrderDetails, email: string, uid: string): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const completedOrder: OrderDetails = {
			...orderDetails,
			items: getState().stripeStore.cartItems || [],
		};

		if (uid) {
			completedOrder.uid = uid;
		}
		// convertObjectToFirebaseObj
		await addUpdateDocumentInCollection<OrderDetails>(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			"stripe_orders",
			{
				...completedOrder,
				...getUpdateProps(undefined, getState().authStore?.uid),
			},
			`${uid || email}-${orderDetails.id}`
		);

		await asyncForEach(completedOrder.items || [], async (item: OrderItem) => {
			const formSubmissionId: string = item.formSubmissionId;
			console.log("asyncForEach formSubmissionId:", formSubmissionId);
			console.log("orderDetails:", orderDetails);
			if (formSubmissionId)
				await addUpdateDocumentInCollection(
					appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
					"form_submissions",
					{ paid: true, stripeOrderNo: orderDetails.id, testOrder: completedOrder.testOrder || false },
					formSubmissionId
				);
		});

		dispatch({
			type: StripeActionTypes.COMPLETE_PAYMENT,
			payload: completedOrder,
		});
	};

export const mapStripeDispatchToProps = (dispatch: AppThunkDispatch) => ({
	addToCart: (item: OrderItem) => dispatch(addToCart(item)),
	setEmail: (email: string) => dispatch(setEmail(email)),
	deleteFromCart: (item: OrderItem) => dispatch(deleteFromCart(item)),
	// loadOrders: (email: string, uid: string) => dispatch(loadOrders(email, uid)),
	// loadPromoCode: (code: string) => dispatch(loadPromoCode(code)),
	clearCart: () => dispatch(clearCart()),
	clearCompletedOrder: () => dispatch(clearCompletedOrder()),
	onCompletePayment: (orderDetails: OrderDetails, email: string, uid: string) =>
		dispatch(onCompletePayment(orderDetails, email, uid)),
	getCartSummary: () => dispatch(getCartSummary()),
});

export type IStripeActionDispatches = ReturnType<typeof mapStripeDispatchToProps>;
export type IStripeStoreProps = ReturnType<typeof mapStripeStateToProps> & ReturnType<typeof mapStripeDispatchToProps>;
