export class StorageConfig {
	public readonly _baseUrl;
	private readonly imageSuffix: string = "?alt=media";

	get imageBaseUrl(): string {
		return;
	}

	constructor(fkey: string) {
		this._baseUrl = `https://firebasestorage.googleapis.com/v0/b/${fkey}.appspot.com/o/`;
	}

	public constructUrl(...params: string[]): string {
		let url = "";
		params.forEach((param, i) => {
			url += encodeURI(param);
			if (i < params.length - 1) {
				url += "%2F";
			}
		});

		url += this.imageSuffix;
		return url;
	}

	public getFilePath(folder: string, itemId: string, fileName: string): string {
		const imgUrl: string = this.constructUrl(this._baseUrl + folder, itemId, fileName);
		return imgUrl;
	}

	public getFilePath2(folder: string, itemId: string, folder2: string, fileName: string): string {
		const imgUrl: string = this.constructUrl(this._baseUrl + folder, itemId, folder2, fileName);
		return imgUrl;
	}

	public static getThumbFilename(size: number, filename: string): string {
		return filename; // simba
		if (filename?.startsWith("thumb@")) return filename;
		return `thumb@${size}_${filename}`;
	}
}
