import { Reducer } from "react";
import { IAction } from "../actions";
import { CollectionActionTypes } from "../actions/collection";
import { IState } from ".";
import { IDirector } from "../../laco-common/base/models/director";

export interface ICollectionState {}

export const initialState: ICollectionState = {};

export const reducer: Reducer<ICollectionState, IAction> = (
	state: ICollectionState = initialState,
	action: IAction
): ICollectionState => {
	switch (action.type) {
		default:
			return state;
	}
};

export function mapCollectionStateToProps(state: IState, props?: any): ICollectionState {
	return state.collectionStore;
}
