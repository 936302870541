import React, { createContext, Context } from "react";
import { IAuthContext } from "../base/auth";

export const AuthContext: Context<IAuthContext> = createContext<IAuthContext>(null);

export type IAuthProps = {
	authContext: IAuthContext;
};

export const withAuth = (Component, forceRefresh?: boolean) => (props) => (
	<AuthContext.Consumer>
		{(state: IAuthContext) => (
			<Component key={forceRefresh ? state.uid : undefined} {...props} authContext={state} />
		)}
	</AuthContext.Consumer>
);

export default AuthContext;
