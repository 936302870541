import { Reducer } from "react";
import { IAction } from "../actions";
import { AuthActionTypes } from "../actions/auth";
import { IState } from ".";
import { PSRole } from "../../models/peer-support";
import { UserCMSRole } from "../../laco-common/base/models/user";
import { ICMSPage } from "../../laco-common/base/models/page";

// export interface IAuthState extends Omit<IAuthStateProps, "tenant"> {
// 	username: string;
// }

export interface IAuthState {
	uid: string;
	psRole: PSRole;
	userRoles: UserCMSRole[];
	pages: ICMSPage[];
	navigation: ICMSPage[];
	permissions: any[];
	refresh: number;
}

export const initialState: IAuthState = {
	uid: undefined,
	psRole: "guest",
	userRoles: null,
	pages: [],
	navigation: [],
	permissions: null,
	refresh: 0,
};

export const reducer: Reducer<IAuthState, IAction> = (
	state: IAuthState = initialState,
	action: IAction
): IAuthState => {
	switch (action.type) {
		case AuthActionTypes.LOGIN: {
			const { uid, psRole } = action.payload;
			return {
				...state,
				uid,
				psRole,
			};
		}
		case AuthActionTypes.LOAD_CMS_ROLES: {
			const { userRoles } = action.payload;
			return {
				...state,
				userRoles,
			};
		}
		case AuthActionTypes.LOAD_CMS_PAGES: {
			const { pages, navigation } = action.payload;
			if (!pages || !pages.length) return state;
			return {
				...state,
				pages,
				navigation,
				refresh: state.refresh + 1,
			};
		}
		case AuthActionTypes.LOAD_PERMISSIONS: {
			const { permissions } = action.payload;
			return {
				...state,
				permissions,
			};
		}
		default:
			return state;
	}
};

export function mapAuthStateToProps(state: IState, props?: any): IAuthState {
	return state.authStore;
}
