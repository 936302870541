import { Reducer } from "react";
import { IAction } from "../actions";
import { FundraiserActionTypes } from "../actions/fundraiser";
import { IState } from ".";
import { IFundraiser } from "../../models/fundraiser";

export interface IFundraiserState {
	fundraiser: IFundraiser;
	fundraisers: IFundraiser[];
}

export const initialState: IFundraiserState = {
	fundraiser: null,
	fundraisers: [],
};

export const reducer: Reducer<IFundraiserState, IAction> = (
	state: IFundraiserState = initialState,
	action: IAction
): IFundraiserState => {
	switch (action.type) {
		case FundraiserActionTypes.LOAD_FUNDRAISER: {
			const fundraiser: IFundraiser = action.payload;
			return {
				...state,
				fundraiser,
			};
		}
		case FundraiserActionTypes.LOAD_FUNDRAISERS: {
			const fundraisers: IFundraiser[] = action.payload;
			return {
				...state,
				fundraisers,
			};
		}
		default:
			return state;
	}
};

export function mapFundraiserStateToProps(state: IState): IFundraiserState {
	return state.fundraiserStore;
}
