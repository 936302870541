import { Reducer } from "react";
import { IAction } from "../actions";
import { GalleryActionTypes } from "../actions/gallery";
import { IState } from ".";
import { ISiteGalleryContent } from "../../models/cms";

export interface IGalleryState {
	galleries: ISiteGalleryContent[];
}

export const initialState: IGalleryState = {
	galleries: null,
};

export const reducer: Reducer<IGalleryState, IAction> = (
	state: IGalleryState = initialState,
	action: IAction
): IGalleryState => {
	switch (action.type) {
		case GalleryActionTypes.LOAD_GALLERIES: {
			const galleries: ISiteGalleryContent[] = action.payload;
			return {
				...state,
				galleries,
			};
		}

		default:
			return state;
	}
};

export function mapGalleryStateToProps(state: IState, props?: any): IGalleryState {
	return state.galleryStore;
}
