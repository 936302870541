import React from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import { HyperLinkProps } from "../laco-common/components/HyperLink";

export default class PlatformUtils {
	public static readonly MAC_USER: boolean = !!window.navigator.platform.match("Mac");
	public static readonly IOS_USER: boolean =
		(!!window.navigator.userAgent.match(/iPad|iPhone|iPod/g) && !window.MSStream) ||
		(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
	public static readonly CTRL_KEY: string = PlatformUtils.MAC_USER ? "Cmd" : "Ctrl";
}

// dana TODO: move to common?
export const renderWebHyperLink = (props: HyperLinkProps) => (
	<TouchableWithoutFeedback>
		<View style={props.style}>
			<a href={props.url} target={!props.notTargetBlank ? "_blank" : undefined}>
				{props.title || props.url}
			</a>
		</View>
	</TouchableWithoutFeedback>
);
