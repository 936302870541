import { IBaseEntity } from "../../laco-common";

export * from "./psFacility";
export * from "../../laco-common/base/models/psAgent";
export * from "../../laco-common/base/models/roles";

export type AddressType = "Work" | "Home";

export type IPSTeam = IBaseEntity & {
	name: string;
};
