import get from "lodash.get";
import { DynamicFormField, DynamicFormValidation, SelectOption, BaseDynamicFormField } from "./dynamic";
import { IFormField, ISelectFormField } from "../models";
import { getCollection } from "../firestore-utils";
import { getUserTooltipDisplay, IAuthContext } from "../auth";
import { ICMSPage } from "../models/page";
import { getCollectionTooltipDisplay, isEmpty } from "../utils";
import { Department } from "../models/department";
import { IFirebaseContext } from "../firebase";

const getDefaultPlaceholder = (field: DynamicFormField): any => {
	if (field.placeholder) return field.placeholder;
	switch (field.fieldType) {
		case "number":
			if (field.isInteger || field.key === "order") return "0";
			if (field.isPercentage) return "0%";
			return "0.00";
		case "email": {
			return "test@example.com";
		}
		default: {
			return "";
		}
	}
};

const getDataNameDisplay = (field: ISelectFormField, pages: ICMSPage[]) => {
	let getItemName;
	if (field.collectionName === "users") {
		getItemName = getUserTooltipDisplay;
	} else {
		const collectionPage: ICMSPage = pages.find((p: ICMSPage) => p.collection === field.collectionName);
		if (collectionPage) {
			console.log("collectionPage:", collectionPage);
			getItemName = getCollectionTooltipDisplay(collectionPage.tooltipFields);
		}
	}
	return getItemName;
};

export const getCollectionSelectOptions = (field: ISelectFormField, getItemName, firebaseContext: IFirebaseContext) => {
	return async () => {
		const items = await getCollection(
			field.useFirebaseAuthApp ? firebaseContext.firebaseApp : firebaseContext.clientFirebase,
			field.collectionName,
			field.collectionDisplayField,
			field.filterQuery
		);
		return (items || []).map((item) => {
			const option: SelectOption = {
				label: getItemName
					? getItemName(item, true) || item[field.collectionDisplayField || "name" || "title"]
					: item[field.collectionDisplayField || "name" || "title"],
				value: item.id,
			};
			return option;
		});
	};
};

export const getSelectOptions = async (
	field: ISelectFormField,
	pages: ICMSPage[],
	authContext: IAuthContext,
	firebaseContext: IFirebaseContext
): Promise<
	| { selectOptions?: SelectOption[]; getSelectOptions?: (authContext?: IAuthContext) => Promise<SelectOption[]> }
	| Partial<BaseDynamicFormField>
> => {
	let getItemName;
	if (field.collectionName) {
		getItemName = getDataNameDisplay(field, pages);
	}
	switch (field.fieldType) {
		case "boolean":
			return {
				selectOptions: [
					{ label: "", value: undefined },
					{ label: "Yes", value: true },
					{ label: "No", value: false },
				],
			};
		case "select":
		case "autocomplete":
		case "collection":
		case "focusRow":
			{
				if (Array.isArray(field.selectValues)) {
					const options: SelectOption[] = field.selectValues.map((item) => {
						if (typeof item === "object") return item as SelectOption;
						return { label: item as string, value: item };
					});
					return { selectOptions: [{ label: "", value: undefined }].concat(options) };
				}
				if (field.collectionName) {
					return {
						getSelectOptions: getSelectOptionsForCollection(
							field,
							firebaseContext,
							authContext,
							getItemName
						),
					};
				}
			}
			break;
		case "chips": {
			if (Array.isArray(field.selectValues)) {
				let options: SelectOption[] = field.selectValues as SelectOption[];
				if (options.length && (typeof options[0] as any) !== "object") {
					options = options.map((value): SelectOption => {
						return { label: value as any as string, value };
					});
				}
				return {
					datasource: options,
					nameField: "label",
					valueField: "value",
					getOptionDisplay: function getOptionDisplay(value: string) {
						console.log("getOptionDisplay:", value);
						if (typeof value === "object") return (value as SelectOption).label;
						const found: SelectOption = (options as SelectOption[]).find(
							(option: SelectOption) => option.value === value
						);
						if (found) return (found.label || "").toString();
						return (value || "").toString();
					},
					getOptionSelected: function (data: SelectOption, value) {
						if (typeof data === "object") return data.value === value;
						return (data || "").toString() === value;
					},
				};
			}
			if (!field.collectionName) {
				// assume free selection
				return {
					datasource: [],
					freeSolo: true,
					getOptionDisplay: (value) => value,
					getOptionSelected: (data, value): boolean => {
						return data === value;
					},
				};
			}

			getItemName = (option) => {
				// getItemName || ((data) => data.id)
				if (option && typeof option === "object") {
					if (field.collectionDisplayField) return option[field.collectionDisplayField];
					return option.id;
				}
				return option;
			};
			let datasource = field.datasource; // TODO: || data[field];
			if (field.collectionName) {
				datasource = await getCollectionData(field, firebaseContext, authContext);
			}

			const nameField: string = (field as any).nameField || "id";
			const valueField: string = (field as any).valueField || "id";

			return {
				datasource,
				nameField,
				valueField,
				getOptionDisplay: getItemName,
				getOptionSelected: (data, value): boolean => {
					if (typeof data === "object") return data[valueField] === value;
					return (data || "").toString() === value;
				},
			};
			break;
		}
		default: {
			return null;
		}
	}
};

export const getSelectOptionsForCollection = (
	field: ISelectFormField,
	firebaseContext: IFirebaseContext,
	authContext: IAuthContext,
	getItemName
): (() => Promise<SelectOption[]>) => {
	return field.isDepartment
		? async () => {
				const datasource = await getCollectionData(field, firebaseContext, authContext);
				return (datasource || []).map((d: Department) => {
					return { label: d.name, value: d.id };
				});
		  }
		: getCollectionSelectOptions(field, getItemName, firebaseContext);
};

const getCollectionData = async (
	field: ISelectFormField,
	firebaseContext: IFirebaseContext,
	authContext: IAuthContext
): Promise<any[]> => {
	let firestoreRef = field.useFirebaseAuthApp ? firebaseContext.firebaseApp : firebaseContext.clientFirebase;
	if (field.isDepartment) {
		if (authContext?.tenantFirebase) {
			firestoreRef = authContext?.tenantFirebase as any;
		}
		if (authContext?.userPayload?.superAdmin) {
			(field as any).freeSolo = true;
		}
	}
	let datasource = await getCollection(firestoreRef, field.collectionName, field.collectionDisplayField);
	if (
		field.isDepartment &&
		authContext?.userPayload?.superAdmin &&
		!field.useFirebaseAuthApp &&
		firestoreRef.firebaseKey !== authContext?.tenant
	) {
		const moreDepartments = await getCollection(
			firebaseContext.clientFirebase,
			field.collectionName,
			field.collectionDisplayField
		);
		datasource = datasource.concat(moreDepartments);
	}
	return datasource;
};

export const getValue = (field: IFormField, data) => {
	let value = get(data, field.id);
	if (value === undefined) value = field.defaultValue;
	switch (field.fieldType) {
		case "phone": {
			value = ("" + value).replace(/\D/g, "");
			if (value.length > 10) return value.substr(1);
			return value;
		}
		case "boolean": {
			if (value !== undefined) return value;
			return undefined;
		}
		case "chips":
			if (!Array.isArray(value)) return [];
			return value;
		case "list": {
			switch (field.listType) {
				case "quotes":
				case "textBlock":
				case "formField":
				case "media":
				default:
					if (!Array.isArray(value)) return [];
					return value;
			}
		}
		case "number":
		case "date":
		case "select":
		case "collection":
		case "autocomplete":
		case "html": {
			return value || "";
		}
		case "imageUrl":
		case "media":
		case "text":
		case "textarea":
		case "link":
		case "color":
		case "email":
		default: {
			return value;
		}
	}
};

export const getDynamicFormFromConfig = async (
	fieldsMap: { [key: string]: IFormField },
	authContext: IAuthContext,
	firebaseContext: IFirebaseContext,
	pages: ICMSPage[],
	data: any = {}
): Promise<DynamicFormValidation> => {
	console.log("getDynamicFormFromConfig(): fieldsMap, data", fieldsMap, data);
	const dynamicFieldsMap: { [key: string]: DynamicFormField } = {};

	for (let field in fieldsMap) {
		const config = { ...fieldsMap[field], id: field, key: field } as any as ISelectFormField;

		const value = getValue(config, data);
		dynamicFieldsMap[field] = {
			...(config as any),
			value,
			valid: config.optional || (!!value && !isEmpty(value)),
			multiline: config.fieldType === "textarea" || field === "desc" || field === "description",
			placeholder: config.placeholder || getDefaultPlaceholder(config as any),
			mandatory: config.optional || config.disabled ? false : true,
			email: config.fieldType === "email",
			numeric: config.fieldType === "number",
			isDate: config.fieldType === "date" || config.fieldType === "datetime",
			...(await getSelectOptions(config, pages, authContext, firebaseContext)),
		};
	}

	const dynamicForm: DynamicFormValidation = new DynamicFormValidation(dynamicFieldsMap);
	return dynamicForm;
};
