import { IAppContext } from "../../contexts/app/AppContext";
import { AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapMarketStateToProps } from "../reducers/market";
import { getCollection } from "../../laco-common/base/firestore-utils";
import { IAdvertisement, IAdvertisementFormatted } from "../../laco-common/base/models/advertisement";
import { displayPrice, displayDate } from "../../laco-common/helpers";
import { IEvent } from "../../laco-common/base/models/event";
import { IImage } from "../../models/Image";
import { FileType } from "../../laco-common/base/models/IFile";
import ClientFirebase from "../../laco-common/firebase/web";

export enum MarketActionTypes {
	LOAD_ADS = "[market] LOAD_ADS",
	LOAD_MESSAGES = "[market] LOAD_MESSAGES",
	LOAD_GROUPS = "[market] LOAD_GROUPS",
}

export const loadAds =
	(preview?: boolean): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		// TODO: filters, order by, categories?
		let ads: IAdvertisementFormatted[] = await getCollection<IAdvertisementFormatted>(
			appContext.firebase.clientFirebase,
			"products",
			"created_at",
			preview
				? (query) => query.where("active", "==", true).limit(4)
				: (query) => query.where("active", "==", true),
			true
		);
		ads = ads.map((ad: IAdvertisement) => {
			return {
				...ad,
				priceFormatted: displayPrice(ad.price, ad.price_type),
				dateFormatted: displayDate(ad),
			};
		});
		dispatch({
			type: MarketActionTypes.LOAD_ADS,
			payload: ads,
		});
		return ads;
	};

export const loadMessages =
	(preview?: boolean): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		// TODO: filters, order by, categories?
		let messages: IEvent[] = await getCollection<IEvent>(
			appContext.firebase.clientFirebase,
			"messages",
			"created_at",
			preview
				? (query) => query.where("active", "==", true).limit(4)
				: (query) => query.where("active", "==", true),
			true
		);
		messages = messages.map((msg: IEvent) => {
			const images: IImage[] = [];
			(msg.images || []).forEach((filename: string, index: number) => {
				const imageUrl: string = ClientFirebase.getStoragePath("messages", msg.id, filename);
				images.push({
					title: msg.title,
					desc: msg.description,
					url: imageUrl,
					filename,
					fileType: FileType.IMAGE,
					order: index,
					preview: false,
				});
			});
			return {
				...msg,
				media: images,
			};
		});

		dispatch({
			type: MarketActionTypes.LOAD_MESSAGES,
			payload: messages,
		});
		return messages;
	};

export const loadGroups =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		let messages: IEvent[] = await getCollection<IEvent>(appContext.firebase.clientFirebase, "groups");
		messages = messages.map((msg: IEvent) => {
			return {
				...msg,
			};
		});
		dispatch({
			type: MarketActionTypes.LOAD_GROUPS,
			payload: messages,
		});
		return messages;
	};

export const mapMarketDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadAds: (preview?: boolean) => dispatch(loadAds(preview)),
	loadMessages: (preview?: boolean) => dispatch(loadMessages(preview)),
	loadGroups: () => dispatch(loadGroups()),
});

export type IMarketActionDispatches = ReturnType<typeof mapMarketDispatchToProps>;
export type IMarketStoreProps = ReturnType<typeof mapMarketStateToProps> & ReturnType<typeof mapMarketDispatchToProps>;
