import { Reducer } from "react";
import { IAction } from "../actions";
import { DirectorActionTypes } from "../actions/director";
import { IState } from ".";
import { IDirector } from "../../laco-common/base/models/director";

export interface IDirectorState {
	directors: IDirector[];
}

export const initialState: IDirectorState = {
	directors: [],
};

export const reducer: Reducer<IDirectorState, IAction> = (
	state: IDirectorState = initialState,
	action: IAction
): IDirectorState => {
	switch (action.type) {
		case DirectorActionTypes.LOAD_DIRECTORS: {
			const directors: IDirector[] = action.payload;
			return {
				...state,
				directors,
			};
		}

		default:
			return state;
	}
};

export function mapDirectorStateToProps(state: IState, props?: any): IDirectorState {
	return state.directorStore;
}
