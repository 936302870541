import { Reducer } from "react";
import { IAction } from "../actions";
import { MarketActionTypes } from "../actions/market";
import { IState } from ".";
import { IAdvertisementFormatted } from "../../laco-common/base/models/advertisement";
import { IEvent } from "../../laco-common/base/models/event";
import { IGroup } from "../../laco-common/base/models/group";

export interface IMarketState {
	ads: IAdvertisementFormatted[];
	messages: IEvent[];
	groups: IGroup[];
	groupsMap: { [groupId: string]: string };
}

export const initialState: IMarketState = {
	ads: [],
	messages: [],
	groups: [],
	groupsMap: {},
};

export const reducer: Reducer<IMarketState, IAction> = (
	state: IMarketState = initialState,
	action: IAction
): IMarketState => {
	switch (action.type) {
		case MarketActionTypes.LOAD_ADS: {
			const ads: IAdvertisementFormatted[] = action.payload;
			return {
				...state,
				ads,
			};
		}
		case MarketActionTypes.LOAD_MESSAGES: {
			const messages: IEvent[] = action.payload;
			return {
				...state,
				messages,
			};
		}
		case MarketActionTypes.LOAD_GROUPS: {
			const groups: IGroup[] = action.payload;
			const groupsMap: { [groupId: string]: string } = { ...(state.groupsMap || {}) };
			groups.forEach((g: IGroup) => {
				groupsMap[g.id] = g.name;
			});
			return {
				...state,
				groups,
				groupsMap,
			};
		}

		default:
			return state;
	}
};

export function mapMarketStateToProps(state: IState, props?: any): IMarketState {
	return state.marketStore;
}
