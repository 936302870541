import { Reducer } from "react";
import { IAction } from "../actions";
import { StreamActionTypes } from "../actions/stream";
import { IState } from ".";
import { IStream } from "../../models/stream";

export interface IStreamState {
	streams: IStream[];
}

export const initialState: IStreamState = {
	streams: [],
};

export const reducer: Reducer<IStreamState, IAction> = (
	state: IStreamState = initialState,
	action: IAction
): IStreamState => {
	switch (action.type) {
		case StreamActionTypes.LOAD_STREAMS: {
			const streams: any[] = action.payload;
			return {
				...state,
				streams,
			};
		}
		default:
			return state;
	}
};

export function mapStreamStateToProps(state: IState, props): IStreamState {
	return { ...state.streamStore, ...props };
}
