import { Reducer } from "react";
import { IAction } from "../actions";
import { EventActionTypes } from "../actions/event";
import { IState } from ".";
import { IEvent } from "../../laco-common/base/models/event";
import { IGroup } from "../../laco-common/base/models/group";

export interface IEventState {
	event: IEvent;
	events: IEvent[];
	homeEvents: IEvent[];
	groups: IGroup[];
	groupsColorsMap: { [groupId: string]: string };
}

export const initialState: IEventState = {
	event: null,
	events: [],
	homeEvents: [],
	groups: [],
	groupsColorsMap: {},
};

export const reducer: Reducer<IEventState, IAction> = (
	state: IEventState = initialState,
	action: IAction
): IEventState => {
	switch (action.type) {
		case EventActionTypes.LOAD_EVENTS: {
			const events: IEvent[] = action.payload;
			return {
				...state,
				events,
			};
		}
		case EventActionTypes.LOAD_HOME_EVENTS: {
			const homeEvents: IEvent[] = action.payload;
			return {
				...state,
				homeEvents,
			};
		}
		case EventActionTypes.LOAD_GROUPS: {
			const groups: IGroup[] = action.payload;
			const groupsColorsMap = state.groupsColorsMap || {};
			groups.forEach((g: IGroup) => {
				if (g.color) {
					groupsColorsMap[g.id] = g.color;
				}
			});
			return {
				...state,
				groups,
				groupsColorsMap,
			};
		}
		default:
			return state;
	}
};

export function mapEventStateToProps(state: IState): IEventState {
	return state.eventStore;
}
