import { ClientFirebase } from "../";
// const firebase = require("firebase/app");
import * as firebase from "firebase/app";
import { loginServiceUser } from "../../base/auth";
require("firebase/firestore");
require("firebase/storage");
require("firebase/functions");
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

// import * as firebase from "firebase/app";
// import 'firebase/firestore';
// import 'firebase/storage';
// import 'firebase/functions';
// import 'firebase/auth';
// import "firebase/firestore";
// import "firebase/storage";

// import 'firebase/auth';

const ClientFirebaseSingleton = (function () {
	let instance: ClientFirebase;

	return {
		getInstance: function () {
			if (!instance) {
				instance = new ClientFirebase(firebase, (clientFirebase, firebaseKey: string) => {
					// firebase.app(firebaseKey).auth().tenantId = firebaseKey; // firebase validates this and doesn't allow all values
					loginServiceUser(firebase.app(firebaseKey));
				});
				console.log("CLIENT FIREBASE INSTANCE CREATED");
			}
			return instance;
		},
	};
})();

export default ClientFirebaseSingleton.getInstance();
