import { combineReducers } from "redux";
import * as fromApp from "./app";
import * as fromAudio from "./audio";
import * as fromAuth from "./auth";
import * as fromCollection from "./collection";
import * as fromDirector from "./director";
import * as fromEvent from "./event";
import * as fromFooter from "./footer";
import * as fromForm from "./form";
import * as fromFundraiser from "./fundraiser";
import * as fromGallery from "./gallery";
import * as fromMarket from "./market";
import * as fromPeerSupport from "./peerSupport";
import * as fromStream from "./stream";
import * as fromStripe from "./stripe";
import * as fromSubscription from "./subscription";
import { IAction } from "../actions";
import { IAppContext } from "../../contexts/app/AppContext";
import { REDUX_STORE_STATE } from "../../models/localStorage";

export type IState = {
	appStore: fromApp.IAppState;
	audioStore: fromAudio.IAudioState;
	authStore: fromAuth.IAuthState;
	collectionStore: fromCollection.ICollectionState;
	directorStore: fromDirector.IDirectorState;
	eventStore: fromEvent.IEventState;
	footerStore: fromFooter.IFooterState;
	formStore: fromForm.IFormState;
	fundraiserStore: fromFundraiser.IFundraiserState;
	galleryStore: fromGallery.IGalleryState;
	marketStore: fromMarket.IMarketState;
	peerSupportStore: fromPeerSupport.IPeerSupportState;
	streamStore: fromStream.IStreamState;
	stripeStore: fromStripe.IStripeState;
	subscriptionStore: fromSubscription.ISubscriptionState;
};

export type IGetState = () => IState;

export const reducers = combineReducers<IState, IAction>({
	appStore: fromApp.reducer,
	audioStore: fromAudio.reducer,
	authStore: fromAuth.reducer,
	collectionStore: fromCollection.reducer,
	directorStore: fromDirector.reducer,
	eventStore: fromEvent.reducer,
	footerStore: fromFooter.reducer,
	formStore: fromForm.reducer,
	fundraiserStore: fromFundraiser.reducer,
	galleryStore: fromGallery.reducer,
	marketStore: fromMarket.reducer,
	peerSupportStore: fromPeerSupport.reducer,
	streamStore: fromStream.reducer,
	stripeStore: fromStripe.reducer,
	subscriptionStore: fromSubscription.reducer,
});

export const getInitialStoreState = (appContext?: IAppContext): IState => {
	return {
		appStore: fromApp.initialState,
		audioStore: fromAudio.initialState,
		authStore: fromAuth.initialState,
		collectionStore: fromCollection.initialState,
		directorStore: fromDirector.initialState,
		eventStore: fromEvent.initialState,
		footerStore: fromFooter.initialState,
		formStore: fromForm.initialState,
		fundraiserStore: fromFundraiser.initialState,
		galleryStore: fromGallery.initialState,
		marketStore: fromMarket.initialState,
		peerSupportStore: fromPeerSupport.initialState,
		streamStore: fromStream.initialState,
		stripeStore: fromStripe.initialState,
		subscriptionStore: fromSubscription.initialState,
		...loadSavedState(appContext),
	};
};

// export const rootReducer = (state: IState, action: IAction) => {
// 	if (action.type === AuthActionTypes.LOGOUT) {
// 		state = getInitialStoreState(); // reset redux state
// 	}
// 	return reducers(state, action);
// };

export const saveState = (state: Partial<IState>, appContext: IAppContext) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(getStoreKeyWithUserContext(appContext), serializedState);
	} catch {}
};

const getStoreKeyWithUserContext = (appContext: IAppContext): string => {
	return REDUX_STORE_STATE; //appContext.auth.awsUser.user.getUsername();
};

const loadSavedState = (appContext: IAppContext) => {
	try {
		const serializedState = localStorage.getItem(getStoreKeyWithUserContext(appContext));
		console.debug("serializedState:", serializedState);
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};
