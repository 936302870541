import * as firebase from "firebase/app";
import FirebaseConfig from "./config";
import { ClientFirebase } from "../laco-common";

export const firebaseApp: ClientFirebase | any = {
	getFirebase: () => firebase.app(),
	init: (firebaseKey: string) => {
		return firebase.app();
	},
};

// export FirebaseConfig;
