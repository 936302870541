import * as firebase from "firebase/app";
import { IAppContext } from "../../contexts/app/AppContext";
import { AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapPeerSupportStateToProps } from "../reducers/peerSupport";
import { getCollection, addUpdateDocumentInCollection, deleteDocument } from "../../laco-common/base/firestore-utils";
import { IPSFacility, IPSAgent, IPSTeam } from "../../models/peer-support";
import { IBaseEntity } from "../../laco-common";
import { updateUserProfile } from "../../laco-common/base/auth";
import { firebaseApp } from "../../firebase";

export enum PeerSupportActionTypes {
	LOAD_FACILITIES = "[peerSupport] LOAD_FACILITIES",
	ADD_UPDATE_FACILITY = "[peerSupport] ADD_UPDATE_FACILITY",
	DELETE_FACILITY = "[peerSupport] DELETE_FACILITY",
	LOAD_AGENTS = "[peerSupport] LOAD_AGENTS",
	ADD_UPDATE_AGENT = "[peerSupport] ADD_UPDATE_AGENT",
	DELETE_AGENT = "[peerSupport] DELETE_AGENT",
	SUBMIT_MONTHLY_REPORT = "[peerSupport] SUBMIT_MONTHLY_REPORT",
	LOAD_MONTHLY_REPORTS = "[peerSupport] LOAD_MONTHLY_REPORTS",
	LOAD_PEER_TEAMS = "[peerSupport] LOAD_PEER_TEAMS",
}

export const getUpdateProps = (id?: string, uid?: string): Partial<IBaseEntity> => {
	const now: number = Date.now();
	const updateProps: Partial<IBaseEntity> = {
		updated_at: now,
	};
	if (!id) {
		updateProps.created_at = now;
	}
	if (uid) {
		updateProps.updated_by = uid;
	}
	return updateProps;
};

export const loadFacilities = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const facilities: IPSFacility[] = await getCollection<IPSFacility>(firebaseApp, "ps_facilities", "name", (query) =>
		query.where("active", "==", true)
	);
	dispatch({
		type: PeerSupportActionTypes.LOAD_FACILITIES,
		payload: facilities,
	});
	return facilities;
};

export const addUpdateFacility = (facility: IPSFacility, id?: string): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const facilityDB: IPSFacility = await addUpdateDocumentInCollection<IPSFacility>(
		firebaseApp,
		"ps_facilities",
		{
			...facility,
			active: true,
			...getUpdateProps(id, getState().authStore.uid),
		},
		id
	);
	const facilities: IPSFacility[] = await getCollection<IPSFacility>(firebaseApp, "ps_facilities", "name", (query) =>
		query.where("active", "==", true)
	);
	dispatch({
		type: PeerSupportActionTypes.ADD_UPDATE_FACILITY,
		payload: facilities,
	});
	return facilities;
};

export const deleteFacility = (facility: IPSFacility): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	await deleteDocument(firebaseApp, "ps_facilities", facility.id);
	setTimeout(() => {
		dispatch({
			type: PeerSupportActionTypes.DELETE_FACILITY,
			payload: facility,
		});
	}, 300);
	return facility;
};

export const loadAgents = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const agents: IPSAgent[] = await getCollection<IPSAgent>(
		firebaseApp,
		"agents",
		"last_name"
		//  (query) =>
		// 	 query.where("active", "==", true)
	);
	dispatch({
		type: PeerSupportActionTypes.LOAD_AGENTS,
		payload: agents,
	});
	return agents;
};

export const addUpdateAgent = (agent: IPSAgent, id?: string): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	await addUpdateDocumentInCollection<IPSAgent>(
		firebaseApp,
		"agents",
		{
			...agent,
			...getUpdateProps(id, getState().authStore.uid),
		},
		id
	);

	// update user by role
	if (agent.uid) {
		await updateUserProfile(agent.uid, firebase, {
			psAdmin:
				agent.role === "Admin" ||
				agent.role === "Coordinator" ||
				agent.role.toLowerCase().includes("admin") ||
				agent.role.toLowerCase().includes("coordinator"),
		});
	}

	const agents: IPSAgent[] = await getCollection<IPSAgent>(
		firebaseApp,
		"agents",
		"last_name"
		// (query) =>
		// 	query.where("active", "==", true)
	);
	dispatch({
		type: PeerSupportActionTypes.ADD_UPDATE_AGENT,
		payload: agents,
	});
	return agents;
};

export const deleteAgent = (agent: IPSAgent): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	await deleteDocument(firebaseApp, "agents", agent.id);
	setTimeout(() => {
		dispatch({
			type: PeerSupportActionTypes.DELETE_AGENT,
			payload: agent,
		});
	}, 300);
	return agent;
};

export const submitMonthlyReport = (report): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const updatedReport = await addUpdateDocumentInCollection<IPSFacility>(firebaseApp, "monthly_reports", report);
	dispatch({
		type: PeerSupportActionTypes.SUBMIT_MONTHLY_REPORT,
		payload: updatedReport,
	});
	return updatedReport;
};

export const loadMonthlyReports = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const reports: any[] = await getCollection<IPSFacility>(
		firebaseApp,
		"monthly_reports",
		"created_at",
		undefined,
		true
	);
	dispatch({
		type: PeerSupportActionTypes.LOAD_MONTHLY_REPORTS,
		payload: reports,
	});
	return reports;
};

export const loadPeerTeams = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const peerTeams: IPSTeam[] = await getCollection<IPSTeam>(firebaseApp, "peer_teams");
	dispatch({
		type: PeerSupportActionTypes.LOAD_PEER_TEAMS,
		payload: peerTeams,
	});
	return peerTeams;
};

export const mapPeerSupportDispatchToProps = (dispatch: AppThunkDispatch) => ({
	addFacility: (facility: IPSFacility) => dispatch(addUpdateFacility(facility)),
	editFacility: (facility: IPSFacility, id: string) => dispatch(addUpdateFacility(facility, id)),
	deleteFacility: (facility: IPSFacility) => dispatch(deleteFacility(facility)),
	loadFacilities: () => dispatch(loadFacilities()),
	loadAgents: () => dispatch(loadAgents()),
	addAgent: (agent: IPSAgent) => dispatch(addUpdateAgent(agent)),
	editAgent: (agent: IPSAgent, id: string) => dispatch(addUpdateAgent(agent, id)),
	deleteAgent: (agent: IPSAgent) => dispatch(deleteAgent(agent)),
	submitMonthlyReport: (report) => dispatch(submitMonthlyReport(report)),
	loadMonthlyReports: () => dispatch(loadMonthlyReports()),
	loadPeerTeams: () => dispatch(loadPeerTeams()),
});

export type IPeerSupportActionDispatches = ReturnType<typeof mapPeerSupportDispatchToProps>;
export type IPeerSupportStoreProps = ReturnType<typeof mapPeerSupportStateToProps> &
	ReturnType<typeof mapPeerSupportDispatchToProps>;
