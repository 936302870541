import React, { Component, CSSProperties, useState } from "react";
import { Box } from "@material-ui/core";
import ExpansionPanel, { ExpansionPanelProps } from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import "./UIComponents.scss";

export type UIBoxProps = {
	title?: any;
	noPadding?: boolean;
	marginBottomClass?: string;
	boxShadow?: number;
};

export interface UIStyleProps {
	className?: string;
	style?: CSSProperties;
}

export interface CollapsibleProps extends ExpansionPanelProps {
	collapseTitle?: React.ReactNode;
}

//  & React.HTMLProps<HTMLElement>
export class UIBox extends React.Component<UIBoxProps & UIStyleProps> {
	// export const UIBox: React.FC<UIBoxProps & UIStyleProps> = props => {
	// private _isMounted: boolean;

	// componentDidMount() {
	// 	this._isMounted = true;
	// }

	// componentWillUnmount() {
	// 	this._isMounted = false;
	// }

	render() {
		// if (!this._isMounted) return null;
		return (
			<Box
				boxShadow={this.props.boxShadow || 1}
				style={this.props.style}
				className={clsx("box-container bg-white", this.props.className, this.props.marginBottomClass || "mb-0")}
			>
				<div className={clsx("card-body", this.props.noPadding ? "p-0" : "")}>
					{this.props.title && <h5>{this.props.title}</h5>}
					{/* <p>{props.children}</p> */}
					<div className={clsx(!this.props.noPadding && "my-1")}>{this.props.children}</div>
				</div>
			</Box>
		);
	}
}

export const UICollapsible: React.FC<CollapsibleProps> = (props) => {
	const defaultExpanded: boolean = props.defaultExpanded !== undefined ? props.defaultExpanded : true;
	return (
		<ExpansionPanel
			className={clsx("ui-collapsible-panel", props.className)}
			square
			disabled={props.disabled}
			defaultExpanded={defaultExpanded}
			expanded={props.expanded}
			id={props.id}
			aria-controls={props["aria-controls"]}
			onChange={props.onChange}
		>
			<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
				<h2 className="mt-2 w-100">{props.collapseTitle}</h2>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>{props.children}</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

export class UICountdown extends Component<{ startCount: number; interval?: number; onComplete?: () => any }> {
	private clearInterval;
	state = {
		count: this.props.startCount,
	};

	componentDidMount() {
		this.clearInterval = setInterval(() => {
			if (this.state.count === 1) {
				if (this.props.onComplete) {
					this.props.onComplete();
				}
				clearInterval(this.clearInterval);
			}
			this.setState({ count: this.state.count - 1 });
		}, this.props.interval || 1000);
	}

	componentWillUnmount() {
		clearInterval(this.clearInterval);
	}

	render() {
		return this.state.count;
	}
}

const UITooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
	const { children, ...rest } = props;
	if (props.title)
		return (
			<Tooltip {...rest}>
				<span>{children}</span>
			</Tooltip>
		);
	return children;
};

export const UIStyledTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.info.main,
		color: "white",
		boxShadow: theme.shadows[1],
		fontSize: 11,
		fontWeight: "normal",
	},
	tooltipPlacementTop: {
		margin: "4px 0",
	},
	tooltipPlacementBottom: {
		margin: "4px 0",
	},
}))(UITooltip);

// export const UIFileIcon: React.FC<{ filename: string } & UIStyleProps> = props => {
// 	const fileSuffix: string = getFileExtension(props.filename);
// 	let iconClass = "text-gray mr-1 ";
// 	iconClass += getIconClass(fileSuffix);
// 	return <i className={clsx(iconClass, props.className)} style={props.style} title={props.filename}></i>;
// };

export function getFileExtension(filename: string = ""): string {
	const lastPeriod: number = filename.lastIndexOf(".");
	if (lastPeriod < 1) return "";
	return filename.substr(lastPeriod + 1).toLowerCase();
}

export function getIconClass(fileSuffix: string): string {
	let iconClass = "";
	switch (fileSuffix) {
		case "xlr":
		case "odr":
		case "xls":
		case "xlsx":
			iconClass += "far fa-file-excel";
			break;
		case "csv":
			iconClass += "fas fa-file-csv";
			break;
		case "pdf":
			iconClass += "far fa-file-pdf";
			break;
		case "doc":
		case "docx":
			iconClass += "far fa-file-word";
			break;
		case "ppt":
		case "pps":
		case "pptx":
			iconClass += "far fa-file-powerpoint";
			break;
		case "ai":
		case "gif":
		case "jpg":
		case "jpeg":
		case "bmp":
		case "ico":
		case "png":
			iconClass += "far fa-file-image";
			break;
		case "32g":
		case "3gp":
		case "h264":
		case "avi":
		case "flv":
		case "m4v":
		case "mkv":
		case "mov":
		case "mp4":
		case "mpg":
		case "mpeg":
		case "rm":
		case "swf":
		case "wmv":
			iconClass += "far fa-file-video";
			break;
		case "aif":
		case "cda":
		case "mid":
		case "midi":
		case "mpa":
		case "ogg":
		case "wav":
		case "wma":
		case "wpl":
			iconClass += "far fa-file-audio";
			break;
		case "txt":
		default:
			iconClass += "far fa-file";
	}
	return iconClass;
}

// contract, portfolio, submission, coa calc
export const UIEntityNotFound: React.FC<{ title?: string; entityName: string; id: string }> = (props) => {
	return (
		<div className="ui-tab-content" style={{ paddingTop: "30px" }}>
			<h3>{props.title || `${props.entityName} not found.`}</h3>
		</div>
	);
};

export const UINoData: React.FC<{ msg?: string } & UIStyleProps> = (props) => {
	return (
		<div className={clsx("ui-no-data d-inline-flex align-items-center text-gray", props.className)}>
			<i className="im im-warning-circle" style={{ marginRight: "5px", fontSize: "10px", marginTop: "2px" }} />
			<span>{props.msg || "No data"}</span>
		</div>
	);
};

export const UIListComma: React.FC<{ arr: any[]; renderer: (item: any) => React.ReactNode } & UIStyleProps> = (
	props
) => (
	<ul className={clsx("ui-data-list", props.className)} style={props.style}>
		{props.arr.map((item: any, i: number) => (
			<li key={i}>
				{props.renderer(item)}
				{i !== props.arr.length - 1 && <span className="comma">,</span>}
			</li>
		))}
	</ul>
);

export const UIButtonWithLoadingProgress: React.FC<
	UIStyleProps & {
		type?: "submit" | "reset" | "button";
		onClick: (e?) => Promise<any>;
		actionName?: string;
		btnTitle?: string;
		disabled?: boolean;
		autoFocus?: boolean;
	}
> = (props) => {
	const [actionExecuting, setActionExecuting] = useState(false);
	return (
		<button
			type={props.type || "button"}
			className={clsx(props.className, "btn", actionExecuting && "btn-loading")}
			style={props.style}
			disabled={actionExecuting || props.disabled}
			title={actionExecuting && props.actionName ? props.actionName : props.btnTitle}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				setActionExecuting(true);
				props.onClick().finally(() => {
					setActionExecuting(false);
				});
			}}
			autoFocus={props.autoFocus}
		>
			{props.children}
		</button>
	);
};

export const UIElWithCircleLoader: React.FC<
	UIStyleProps & {
		onClick: (e?) => Promise<any>;
		children: React.ReactNode;
		actionName?: string;
		title?: string;
		loaderSize?: number;
		loaderStyle?: CSSProperties;
	}
> = (props) => {
	const [actionExecuting, setActionExecuting] = useState(false);
	return (
		<UIStyledTooltip title={actionExecuting && props.actionName ? props.actionName : props.title}>
			<span
				style={props.style}
				className={clsx(
					props.className,
					"position-relative ui-el-circle-loader",
					actionExecuting && "ui-el-circle-loader-processing"
				)}
				onClick={
					actionExecuting
						? undefined
						: (e) => {
								e.preventDefault();
								e.stopPropagation();
								setActionExecuting(true);
								props.onClick().finally(() => {
									setActionExecuting(false);
								});
						  }
				}
			>
				<span className="ui-el-circle-loader-content">{props.children}</span>
				{!!actionExecuting && (
					<CircularProgress
						className="position-absolute"
						style={props.loaderStyle || { right: "-1px", top: "2px" }}
						size={props.loaderSize || 18}
					/>
				)}
			</span>
		</UIStyledTooltip>
	);
};
