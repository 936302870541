const timeZone = "America/Los_Angeles";

export function getDateStringInTimezone(milliseconds: number, returnNowIfEmpty?: boolean): string {
	if (!milliseconds && !returnNowIfEmpty) return "";
	const d: Date = milliseconds ? new Date(milliseconds) : new Date();
	const dateString: string = d.toLocaleString("en-US", {
		timeZone,
	});
	return dateString;
}

export async function asyncForEach(array, callback) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
}

export const isEmpty = (value: any): boolean => {
	return value === null || value === undefined || value === "";
};

export const getCollectionTooltipDisplay = (tooltipFields?: string[]) => {
	return tooltipFields?.length
		? (data): string => {
				if (!data) return undefined;
				const displayValues: string[] = [];
				tooltipFields.forEach((field: string) => {
					if (data[field]) {
						displayValues.push(data[field]);
					}
				});
				// console.log("getCollectionTooltipDisplay tooltipFields:", tooltipFields);
				// console.log("displayValues:", displayValues.length ? displayValues[0] : "");
				if (!displayValues.length) return undefined;
				return displayValues.join(" ");
		  }
		: undefined; // (data) => (data?.id ? `#${data.id}` : "");
};
