export enum FileType {
	IMAGE = "image",
	VIDEO = "video",
	AUDIO = "audio",
	FILE = "file",
}

export interface IFile {
	docId?: string;
	filename: string;
	fileType: FileType;
	url: string;
	uploadTask?: any; //firebase.storage.UploadTask;
	uploadFileRef?: any; //firebase.storage.Reference;
	order?: number; // add fields participating in query
	preview?: boolean;
	isProfile?: boolean;
	displayDate?: number;
	mediaDate?: Date;
	title?: string;
	thumbUrl?: string;
}
