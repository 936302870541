import { Reducer } from "react";
import { IAction } from "../actions";
import { SubscriptionActionTypes } from "../actions/subscription";
import { IState } from ".";
import { IMembershipDetails, BWMembershipStatus } from "../../models/bwMembership";

export interface ISubscriptionState {
	membershipDetails: IMembershipDetails;
}

export const initialState: ISubscriptionState = {
	membershipDetails: { status: BWMembershipStatus.INACTIVE, isSubscriber: true },
};

export const reducer: Reducer<ISubscriptionState, IAction> = (
	state: ISubscriptionState = initialState,
	action: IAction
): ISubscriptionState => {
	switch (action.type) {
		case SubscriptionActionTypes.LOAD_SUBSCRIPTION:
		case SubscriptionActionTypes.ACTIVATE_SUBSCRIPTION: {
			const membershipDetails: IMembershipDetails = action.payload;
			console.log("membershipDetails:", membershipDetails);
			return {
				...state,
				membershipDetails,
			};
		}

		default:
			return state;
	}
};

export function mapSubscriptionStateToProps(state: IState, props?: any): ISubscriptionState {
	return state.subscriptionStore;
}
