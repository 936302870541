import React from "react";
import { InputLabel } from "@material-ui/core";
// import CodeInput from "./CodeInput.tsx__";
// import DateInput from "./DateInput.tsx__";
// import TextSelectInput from "./TextSelectInput.tsx__";
import PasswordInput from "./PasswordInput";
import TextSelectInput from "./TextSelectInput";
import DateInput from "./DateInput";
import { IFormInputProps, ISelectFormField } from "../../laco-common";
import GoogleAutoComplete from "../../components/google-maps/AutoComplete";
import { Place, UnmatchedPlace, addressValidator } from "../../models/googleMaps";
import { DynamicFormField, DynamicFormValidation } from "../../laco-common/base/form-validation/dynamic";
import { Address } from "../../laco-common/base/models/address";

export const FormLabel: React.FC<{
	field: DynamicFormField;
	getForm?: () => DynamicFormValidation;
	withAsterik?: boolean;
}> = (props: { field: DynamicFormField; getForm?: () => DynamicFormValidation; withAsterik?: boolean }) => {
	const field = props.field;
	return (
		<InputLabel htmlFor={field.key}>
			{field.label}
			{field.mandatory && <span className="text-primary pl-1">*</span>}
			{!!field.infoText && (
				<i>
					<i className="im im-info ml-3 mr-1"></i>
					{field.infoText}
				</i>
			)}
			{field.postLabelContent}
		</InputLabel>
	);
};

export { PasswordInput, TextSelectInput, DateInput };

export const renderFormInputPhone = (props: IFormInputProps) => {
	const formField = props.formField as any;
	let number: string = ("" + formField.value).replace(/\D/g, "");
	if (number.length > 10) {
		number = number.substr(1);
	}
	const field: DynamicFormField = {
		...(props.formField as any),
		...props.customProps.field,
		isPhoneNo: true,
		value: number,
		defaultValue: number,
	};

	return (
		<div className="form-row w-100 mb-4">
			<TextSelectInput
				{...props}
				field={field}
				// onBlur={(event) => {
				// 	return props.customProps.onInputChange(props.customProps.field, number);
				// }}
				// onChange={(event) => {
				// 	console.log("phone renderer event:", event);
				// 	number = event.target.value;
				// 	return event.target.value;
				// 	// const returnValue = props.customProps.onInputChange(props.customProps.field, event.target.value);
				// 	// return returnValue;
				// }}
				onChange={(number) => {
					return props.customProps.onInputChange(props.customProps.field, number);
				}}
			/>
		</div>
	);
};

export const renderFormInputTextSelect = (props: IFormInputProps) => {
	const formField = (props.formField as any) as DynamicFormField;
	const field: DynamicFormField & ISelectFormField = {
		...(props.formField as any),
		...props.customProps.field,
		isPhoneNo: formField.fieldType === "phone",
		value: formField.value,
		defaultValue: formField.defaultValue,
	};

	if (field.fieldType === "yes/no") {
		field.selectValues = ["", "Yes", "No"];
	}

	return (
		<div className="form-row w-100 mb-4">
			<TextSelectInput
				{...props}
				field={field}
				onChange={(val) => {
					return props.customProps.onInputChange(props.customProps.field, val);
				}}
			/>
		</div>
	);
};

export const renderFormInputAddress = (props: IFormInputProps) => {
	const field: DynamicFormField = { ...(props.formField as any), ...props.customProps.field };
	// const validationResult = addressValidator(field, null);
	return (
		<GoogleAutoComplete
			label={<FormLabel field={field} />}
			placeholder={field.placeholder}
			enforceValidPlace={true}
			defaultValue={field.value as Address}
			field={field}
			// invalid={!!validationResult.errType}
			// invalidMsg={validationResult.msg}
			showImage={true}
			onPlaceSelected={(place: Place | UnmatchedPlace) => {
				return props.customProps.onInputChange(props.customProps.field, place);
			}}
		/>
	);
};
