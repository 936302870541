// @ts-nocheck
// @ts-ignore
import { ReplaySubject } from "rxjs";
import { Platform } from "react-native";
import { firebaseClientsConfig } from "../base/firebase/clients";
import { StorageConfig } from "./storage";
import { getDocument } from "../base/firestore-utils";
import { IClientFirebase } from "../base/firebase";

type FirebaseApp = any; //firebase.app.App | App | undefined | null;

export interface Payload {
	clientFirebase: FirebaseApp;
}

export const loadTenantFromFirebase = async (firebase, tenant: string) => {
	const firebaseApp = {
		getFirebase: () => firebase.app(),
		init: (firebaseKey: string) => {
			return firebase.app();
		},
	};
	const tenantConfig = await getDocument(firebaseApp, "tenants", tenant);
	console.log("tenantConfig:", tenantConfig);
	return tenantConfig;
};

export class ClientFirebase implements IClientFirebase {
	private _firebaseKey: string;
	private _firebase;
	private _onInit: (clientFirebase: FirebaseApp, firebaseKey: string) => any;
	private _clientFirebase: FirebaseApp;
	private _storage: StorageConfig;
	public events = new ReplaySubject<Payload>(1);
	private _initializedClients: { [key]: FirebaseApp } = {};
	private _tenantConfig = null;

	public get storage() {
		return this._storage;
	}

	public get firebaseKey(): string {
		return this._firebaseKey;
	}

	public get hasPeerSupport(): boolean {
		return this._tenantConfig?.hasPeerSupport;
	}

	public get displayName(): string {
		return this._tenantConfig.displayName;
	}

	public constructor(firebase, onInit) {
		this._firebase = firebase;
		this._onInit = onInit;
	}

	// dynamic firebase selection by user - once creds are loaded init firebase
	public async init(firebaseKey: string, loadTenantsFromFirebase?: boolean) {
		console.log("INIT FIREBASE KEY:", firebaseKey);
		this._firebaseKey = firebaseKey?.trim();
		const response = await this._initClient(this._firebaseKey, loadTenantsFromFirebase);
		if (!response){
			console.error("No firebase config for key:", firebaseKey);
			return null;
		}
		const { client, tenantConfig } = response;
		this._clientFirebase = client;
		if (tenantConfig) {
			this._tenantConfig = tenantConfig;
		}
		console.log("_clientFirebase:", this._clientFirebase);
		console.log("this._tenantConfig:", this._tenantConfig);
		this._onInit(this._clientFirebase, firebaseKey);
		this._storage = new StorageConfig(this._firebaseKey);
		this.events.next({ clientFirebase: this._clientFirebase }); // for components that need to update state
		return this._clientFirebase;
	}

	public async initAdditional(firebaseKey: string, loadTenantsFromFirebase?: boolean) {
		console.log("initAdditional INIT FIREBASE KEY:", firebaseKey);
		const { client, tenantConfig } = await this._initClient(firebaseKey, loadTenantsFromFirebase);
		if (!client || !firebaseKey) return null;
		const wrappedClient: Partial<ClientFirebase> = {
			firebaseKey,
			getFirebase: () => client,
			init: (firebaseKey: string) => {
				return Promise.resolve(client);
			},
			hasPeerSupport: tenantConfig?.hasPeerSupport,
		};
		this._initializedClients[firebaseKey?.trim()] = wrappedClient;
		return wrappedClient;
	}

	public async clientFirebaseReady() {
		console.log("clientFirebaseReady()");
		await this.init(this._firebaseKey, true);
	}

	public getFirebase(): FirebaseApp {
		if (!this._clientFirebase) {
			console.log(
				"ClientFirebase not initialized, call init() first. Attempting to initialize with:",
				this._firebaseKey
			);
			// if key exists try to initialize
			if (this._firebaseKey) {
				return this.init(this._firebaseKey, true);
			} else {
				return null;
			}
		}

		return this._clientFirebase;
	}

	public deleteFirebase() {
		delete this._clientFirebase;
	}

	public getStoragePath(folder: string, itemId: string, fileName: string): string {
		return this._storage.getFilePath(folder, itemId, fileName);
	}

	public getStoragePath2(folder: string, subFolder: string, itemId: string, fileName: string): string {
		return this._storage.getFilePath2(folder, subFolder, itemId, fileName);
	}

	private async _initClient(firebaseKey: string, loadTenantsFromFirebase?: boolean) {
		console.log("INIT FIREBASE KEY:", firebaseKey);
		console.log("Platform:", Platform.OS);
		if (this._firebaseKey && this._firebaseKey === firebaseKey && !!this._clientFirebase) {
			console.log("Client Firebase has already been initialized with:", this._firebaseKey);
			return { client: this._clientFirebase };
		}
		if (this._initializedClients[firebaseKey]) {
			console.log("Client Firebase has already been initialized with:", firebaseKey);
			return { client: this._initializedClients[firebaseKey] };
		}
		const platformKey: string = Platform.OS;
		console.log("Client Firebase init [firebaseKey]:", firebaseKey);
		if (!firebaseKey) {
			console.log("Invalid firebaseKey:", firebaseKey);
			return null;
		}
		const key = firebaseKey.trim();
		let tenantConfig;
		if (loadTenantsFromFirebase) {
			tenantConfig = await loadTenantFromFirebase(this._firebase, key);
		} else {
			tenantConfig = firebaseClientsConfig[key];
		}

		if (!tenantConfig) {
			console.log("Invalid tenant:", key);
			return null;
		}
		const fConfig = tenantConfig[platformKey];
		if (!fConfig) {
			console.log("Error occured initializing db:", key);
			return null;
		}

		const client = this._firebase.initializeApp(fConfig, key);
		console.log("client:", client);

		return { client, tenantConfig };
	}
}
