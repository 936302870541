import { AppThunkResult, AppThunkDispatch } from "./index";
import { IAppContext } from "../../contexts/app/AppContext";
import { IGetState } from "../reducers";
import { getCollection, getForm } from "../../laco-common/base/firestore-utils";
import { IForm } from "../../laco-common";
import { IPage } from "../../laco-common/base/models/page";
import { IAuthContext } from "../../laco-common/base/auth";
import { firebaseApp } from "../../firebase";

const COLLECTION: string = "forms";

export enum FormActionTypes {
	LOAD_FORMS = "[form] LOAD_FORMS",
	LOAD_PS_FORMS = "[form] LOAD_PS_FORMS",
}

export const loadForms =
	(authContext: IAuthContext): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const q =
			authContext.userPayload && authContext.userPayload.role === "admin"
				? undefined
				: (query) => query.where("active", "==", true);
		let forms: IForm[] = await getCollection<IForm>(appContext.firebase.clientFirebase, COLLECTION, "order", q);
		const formPages: IPage[] = getState().appStore.pages.filter(
			(p: IPage) => !!p.formId && !Array.isArray(p.formId) && p.active
		);
		console.log("formPages:", formPages);
		// forms = forms.filter((f: IForm) => !formPages.find((p: IPage) => p.formId === f.id));
		dispatch({
			type: FormActionTypes.LOAD_FORMS,
			payload: { forms, formPages },
		});
		return forms;
	};

export const loadPSForms =
	(authContext: IAuthContext): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const q =
			authContext.userPayload && authContext.userPayload.role === "admin"
				? undefined
				: (query) => query.where("active", "==", true);
		let forms: IForm[] = await getCollection<IForm>(firebaseApp, COLLECTION, "order", q);
		dispatch({
			type: FormActionTypes.LOAD_PS_FORMS,
			payload: { forms },
		});
		return forms;
	};

export const mapFormDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadForms: (authContext: IAuthContext) => dispatch(loadForms(authContext)),
	loadPSForms: (authContext: IAuthContext) => dispatch(loadPSForms(authContext)),
});

export type IFormActionDispatches = ReturnType<typeof mapFormDispatchToProps>;
