import React, { useState, useCallback } from "react";
import clsx from "clsx";
import Autocomplete from "react-google-autocomplete";
import { Place, PlaceType, UnmatchedPlace, getGeoLocation, addressValidator } from "../../models/googleMaps";
import { AddressImage, AddressImageProps } from "./Address";
import { FormField, ICustomValidatorResult } from "../../laco-common/base/form-validation";
import { Address, AddressBase } from "../../laco-common/base/models/address";
import "./AutoComplete.scss";

type GoogleAutoCompleteProps = {
	onPlaceSelected: (place: Place | UnmatchedPlace) => any;
	placeType?: PlaceType;
	enforceValidPlace?: boolean;
	placeholder?: string;
	label?: string | React.ReactNode;
	defaultValue?: Address;
	invalidMsg?: string;
	showImage?: boolean;
	className?: string;
	children?: React.ReactNode;
	// invalid?: boolean;
	field?: FormField;
	addressImageProps?: Partial<AddressImageProps>;
};

const GoogleAutoComplete: React.FC<GoogleAutoCompleteProps> = (props: GoogleAutoCompleteProps) => {
	const placeType: PlaceType = props.placeType || "geocode";
	let validationResult: ICustomValidatorResult =
		props.enforceValidPlace && props.defaultValue ? addressValidator(props.field, null) : undefined;
	const [invalid, setInvalid] = useState<boolean>(!!validationResult?.errType);
	const [invalidMsg, setInvalidMsg] = useState<string>(
		validationResult?.msg || props.invalidMsg || "Invalid location"
	);
	const [address, setAddress] = useState<AddressBase>(props.defaultValue);
	let _inputRef;

	// handle empty input to address
	const inputRef = useCallback((node) => {
		if (node !== null && !_inputRef) {
			_inputRef = node;
			if (node.refs.input)
				node.refs.input.onchange = (e) => {
					if (e?.target?.value) return; // will be handled onPlaceSelected
					props.onPlaceSelected(null);
				};
		}
	}, []);

	return (
		<div className={clsx("form-row w-100 mb-4", props.className)}>
			{props.label}
			{/* <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated">Dana label</label> */}
			<div className="MuiFormControl-root MuiTextField-root w-100">
				<div
					className={clsx(
						"MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl",
						invalid && "Mui-error"
					)}
				>
					<Autocomplete
						className="MuiInputBase-input MuiOutlinedInput-input"
						defaultValue={props.defaultValue?.formatted_address}
						// apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
						ref={inputRef}
						style={{ width: "100%" }}
						placeholder={props.placeholder || "Enter Address"}
						onPlaceSelected={(place: Place | UnmatchedPlace) => {
							const p = place as Place;
							if (props.enforceValidPlace) {
								props.field.value = p;
								validationResult = addressValidator(props.field, null);
							}
							if (props.enforceValidPlace && !!validationResult?.errType) {
								setInvalid(true);
								setInvalidMsg(validationResult.msg || invalidMsg);
							} else {
								setInvalid(false);
								setAddress({
									formatted_address: p.formatted_address,
									...getGeoLocation(p),
									zip_code: "",
								});
								props.onPlaceSelected(place);
							}
						}}
						types={[placeType]}
						componentRestrictions={{ country: "us" }}
					/>
					<fieldset
						aria-hidden="true"
						className="PrivateNotchedOutline-root-208 MuiOutlinedInput-notchedOutline"
						style={{ paddingLeft: "8px" }}
					>
						<legend className="PrivateNotchedOutline-legend-209" style={{ width: "0.01px" }}>
							<span>​</span>
						</legend>
					</fieldset>
				</div>

				{invalid && (
					<p
						className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled my-0 py-0 position-absolute"
						style={{ fontSize: "12px", color: "rgba(238, 17, 17, 0.95)", bottom: "-21px" }}
					>
						{invalidMsg}
					</p>
				)}
				{props.showImage && !invalid && (
					<AddressImage className="mt-2_5" address={address} {...props.addressImageProps} />
				)}
			</div>
			{props.children}
		</div>
	);
};

export default GoogleAutoComplete;
