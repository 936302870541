export const firebaseClientsConfig = {
	"backcountry-bayit": {
		ios: {

		},
		android: {

		},
		web: {
			apiKey: "AIzaSyDLQD6r_megG9lE-00OoZvWseeME0vcX6M",
			authDomain: "backcountry-bayit.firebaseapp.com",
			projectId: "backcountry-bayit",
			storageBucket: "backcountry-bayit.appspot.com",
			messagingSenderId: "1007737899547",
			appId: "1:1007737899547:web:e775650014fd2342a406be",
			measurementId: "G-CY8DPVKDGY"
		},
	}
};
