import * as firebase from "firebase/app";
const DEFAULT_DATE: Date = new Date();

export function getDateDisplay(
	from: Date,
	to: Date,
	toDelimiter: string = " to ",
	omitDay?: boolean,
	nullToAsPresent?: boolean
): string {
	let str: string = "";
	let fromStr = from ? (omitDay ? getMonthDateDisplay(from) : from.toDateString().substr(4)) : "";
	let toStr = !!to
		? omitDay
			? getMonthDateDisplay(to)
			: to.toDateString().substr(4)
		: nullToAsPresent && from
		? " Present"
		: "";
	// compare that not the same dates
	if (fromStr && fromStr === toStr) return fromStr;

	if (fromStr) str += fromStr;
	if (from && (to || nullToAsPresent)) str += toDelimiter;
	if (toStr) str += toStr;
	return str;
}

export function getDateString(d: Date, withTime?: boolean): string {
	if (!d) return "";
	const timeStr: string = withTime
		? ` ${d.toLocaleTimeString(undefined, {
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				hour12: true,
		  })}`
		: "";
	return `${d.getFullYear()}-${getDoubleDigit(d.getMonth() + 1)}-${getDoubleDigit(d.getDate())}${timeStr}`;
}

function getDoubleDigit(val: number): string | number {
	if (val < 10) return `0${val}`;
	return val;
}

export const convertDateFromFirebase = (val: firebase.firestore.Timestamp | string | number | any): Date => {
	if (!val) return null;
	if (!val.seconds) return new Date(val);
	const t: firebase.firestore.Timestamp = new firebase.firestore.Timestamp(val.seconds, val.nanoseconds);
	return t.toDate();
};

export const compareDates = (date: Date, compareTo: Date, withTime?: boolean): ">" | "<" | "=" => {
	if (!date || !compareTo) return undefined;
	const time: number = withTime ? date.getTime() : date.setHours(0, 0, 0, 0);
	const compareToTime: number = withTime ? compareTo.getTime() : compareTo.setHours(0, 0, 0, 0);
	console.debug("compareDates() time, compareToTime:", date, compareTo, time, compareToTime);
	if (time === compareToTime) return "=";
	if (time > compareToTime) return ">";
	if (time < compareToTime) return "<";

	return undefined;
};

export function getMonthDateDisplay(d: Date): string {
	let month: string = "";
	if (!d.getMonth) return "";
	switch (d.getMonth()) {
		case 0:
			month = "January";
			break;
		case 1:
			month = "February";
			break;
		case 2:
			month = "March";
			break;
		case 3:
			month = "April";
			break;
		case 4:
			month = "May";
			break;
		case 5:
			month = "June";
			break;
		case 6:
			month = "July";
			break;
		case 7:
			month = "August";
			break;
		case 8:
			month = "September";
			break;
		case 9:
			month = "October";
			break;
		case 10:
			month = "November";
			break;
		case 11:
			month = "December";
			break;
	}
	return `${month} ${d.getFullYear()}`;
}

export const getFullMonthName = (no: number) => {
	switch (no) {
		case 0:
			return "January";
		case 1:
			return "February";
		case 2:
			return "March";
		case 3:
			return "April";
		case 4:
			return "May";
		case 5:
			return "June";
		case 6:
			return "July";
		case 7:
			return "August";
		case 8:
			return "September";
		case 9:
			return "October";
		case 10:
			return "November";
		case 11:
			return "December";
	}
};
