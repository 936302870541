import { AppThunkResult, AppThunkDispatch } from "./index";
import { IAppContext } from "../../contexts/app/AppContext";
import { IGetState } from "../reducers";
import { getCollection, getDocument } from "../../laco-common/base/firestore-utils";
import { IFundraiser } from "../../models/fundraiser";

const COLLECTION: string = "fundraisers";

export enum FundraiserActionTypes {
	LOAD_FUNDRAISER = "[fundraiser] LOAD_FUNDRAISER",
	LOAD_FUNDRAISERS = "[fundraiser] LOAD_FUNDRAISERS",
}

export const loadFundraiser =
	(id: string): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const fundraiser: IFundraiser = await getDocument<IFundraiser>(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			COLLECTION,
			id
		);
		dispatch({
			type: FundraiserActionTypes.LOAD_FUNDRAISER,
			payload: fundraiser,
		});
		return fundraiser;
	};

export const loadFundraisers =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const fundraisers: IFundraiser[] = await getCollection<IFundraiser>(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			COLLECTION,
			"created_at",
			(query) => query.where("active", "==", true),
			true
		);
		dispatch({
			type: FundraiserActionTypes.LOAD_FUNDRAISERS,
			payload: fundraisers,
		});
		return fundraisers;
	};

export const mapFundraiserDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadFundraiser: (id: string) => dispatch(loadFundraiser(id)),
	loadFundraisers: () => dispatch(loadFundraisers()),
});

export type IFundraiserActionDispatches = ReturnType<typeof mapFundraiserDispatchToProps>;
