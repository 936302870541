import { Reducer } from "react";
import { IAction } from "../actions";
import { PeerSupportActionTypes } from "../actions/peerSupport";
import { IState } from ".";
import { IPSFacility, IPSAgent, IPSTeam } from "../../models/peer-support";

export interface IPeerSupportState {
	facilities: IPSFacility[];
	agents: IPSAgent[];
	reports: any[];
	peerTeams: IPSTeam[];
	peerTeamsMap: { [id: string]: IPSTeam };
}

export const initialState: IPeerSupportState = {
	facilities: [],
	agents: [],
	reports: null,
	peerTeams: [],
	peerTeamsMap: {},
};

export const reducer: Reducer<IPeerSupportState, IAction> = (
	state: IPeerSupportState = initialState,
	action: IAction
): IPeerSupportState => {
	switch (action.type) {
		case PeerSupportActionTypes.LOAD_FACILITIES:
		case PeerSupportActionTypes.ADD_UPDATE_FACILITY: {
			const facilities: IPSFacility[] = action.payload;
			return {
				...state,
				facilities,
			};
		}
		case PeerSupportActionTypes.DELETE_FACILITY: {
			const deletedFacility: IPSFacility = action.payload;
			return {
				...state,
				facilities: state.facilities.filter((f: IPSFacility) => f.id !== deletedFacility.id),
			};
		}
		case PeerSupportActionTypes.LOAD_AGENTS:
		case PeerSupportActionTypes.ADD_UPDATE_AGENT: {
			const agents: IPSAgent[] = action.payload;
			return {
				...state,
				agents,
			};
		}
		case PeerSupportActionTypes.DELETE_AGENT: {
			const deletedAgent: IPSAgent = action.payload;
			return {
				...state,
				agents: state.agents.filter((a: IPSAgent) => a.id !== deletedAgent.id),
			};
		}
		case PeerSupportActionTypes.LOAD_MONTHLY_REPORTS: {
			const reports: any[] = action.payload;
			return {
				...state,
				reports,
			};
		}
		case PeerSupportActionTypes.LOAD_PEER_TEAMS: {
			const peerTeams: IPSTeam[] = action.payload;
			const peerTeamsMap = {};
			peerTeams.forEach((t: IPSTeam) => {
				peerTeamsMap[t.id] = t;
			});
			return {
				...state,
				peerTeams,
				peerTeamsMap,
			};
		}
		default:
			return state;
	}
};

export function mapPeerSupportStateToProps(state: IState): IPeerSupportState {
	return state.peerSupportStore;
}
