import { Reducer } from "react";
import { IAction } from "../actions";
import { FooterActionTypes } from "../actions/footer";
import { IState } from ".";
import { IDirector } from "../../laco-common/base/models/director";
import { IResource } from "../../models/footer";
import { ILink } from "../../models/cms";

export interface IFooterState {
	president: IDirector;
	resources: IResource[];
	linksCol1: ILink[];
	linksCol2: ILink[];
	facebookLink?: string;
	instagramLink?: string;
	youtubeLink?: string;
	twitterLink?: string;
	copyrightName: string;
}

export const initialState: IFooterState = {
	president: null,
	resources: [],
	linksCol1: [],
	linksCol2: [],
	facebookLink: "",
	instagramLink: "",
	copyrightName: "",
};

export const reducer: Reducer<IFooterState, IAction> = (
	state: IFooterState = initialState,
	action: IAction
): IFooterState => {
	switch (action.type) {
		case FooterActionTypes.LOAD_FOOTER: {
			const payload: IFooterState = action.payload;
			return {
				...state,
				...payload,
			};
		}

		default:
			return state;
	}
};

export function mapFooterStateToProps(state: IState, props?: any): IFooterState {
	return state.footerStore;
}
