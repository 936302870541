import React, { createContext, Context } from "react";
import { IFirebaseContext } from "../base/firebase";

export const FirebaseContext: Context<IFirebaseContext> = createContext<IFirebaseContext>({
	tenant: "",
	clientFirebase: null,
	userPayload: null,
});

export const FirebaseProvider = FirebaseContext.Provider;
export const FirebaseConsumer = FirebaseContext.Consumer;
export const withFirebaseHOC = (Component) => (props) => (
	<FirebaseConsumer>{(state) => <Component {...props} firebase={state} />}</FirebaseConsumer>
);

//  https://levelup.gitconnected.com/how-to-use-the-react-context-api-to-build-react-native-expo-and-firebase-apps-adda840e52b0
