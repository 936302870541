import { DynamicFormValidation, DynamicFormField } from "./dynamic";
import { FormFieldType } from "../models";
import { asyncForEach } from "../utils";

export type BaseFormField = {
	fieldType?: FormFieldType;
	numeric?: boolean;
	integer?: boolean;
	isDate?: boolean;
	isUrl?: boolean;
	maxDateToday?: boolean;
	minLength?: number;
	maxLength?: number;
	min?: number;
	max?: number;
};

export type FormField = BaseFormField & {
	value: any;
	key?: string;
	mandatory?: boolean;
	valid?: boolean;
	touched?: boolean;
	email?: boolean;
	customValidator?: CustomValidator | CustomValidator[];
	asyncCustomValidator?: (field: FormField, form: FormValidation) => Promise<ICustomValidatorResult>;
	compareToField?: string;
	errors?: { [errType: string]: ValidationError };
	errorsArr?: ValidationError[];
	errorsConfig?: { [errType: string]: ValidationError };
	form?: DynamicFormValidation;
	onChange?: (field: FormField, form: FormValidation) => { [field: string]: any };
	defaultValue?: any;
};

export type CustomValidator = (field: FormField, form?: FormValidation) => ICustomValidatorResult;

export interface ICustomValidatorResult {
	field: FormField;
	errType?: ErrorType;
	msg?: string;
}

export enum ErrorType {
	Mandatory = "Mandatory",
	Email = "Email",
	Phone = "Phone",
	Numeric = "Numeric",
	Date = "Date",
	Minlength = "Minlength",
	Maxlength = "Maxlength",
	Compare = "Compare",
	Invalid = "Invalid",
}

export interface ValidationError {
	errType: ErrorType;
	message: string;
}

export class FormValidation {
	public readonly fields: { [index: string]: FormField };
	public errors: { [index: string]: ValidationError } = {};
	public refresh: number = 0;

	get isValid(): boolean {
		for (let key in this.fields) {
			if (!this.fields[key].valid) return false;
		}
		return true;
	}

	get values(): any {
		let formValues = {};
		for (let key in this.fields) {
			formValues[key] = this.fields[key].value;
			if (this.fields[key].numeric) formValues[key] = Number(this.fields[key].value);
		}
		return formValues;
	}

	constructor(fields: { [index: string]: FormField }) {
		this.fields = fields;
		for (let field in fields) {
			fields[field].key = field;
		}
	}

	public formFieldOnBlur(field: string, e: any, isPristine?: boolean): FormValidation {
		const updated: FormField = this.fieldOnBlur(field, e, isPristine);
		this.fields[field] = updated;
		console.debug("UPDATED FORM:", this);
		return this;
	}

	public fieldOnBlur(field: string, e: any, isPristine?: boolean): FormField {
		const newValue: any = e && e.target ? e.target.value : e; //.trim();
		const hasChange: boolean = newValue !== this.fields[field].value;
		this.fields[field].value = newValue;
		this.fields[field].touched = true;
		let updated: FormField = this.validateField(this.fields[field], isPristine);
		if (this.fields[field].onChange && hasChange) {
			const updatedFields = this.fields[field].onChange(updated, this);
			for (let key in updatedFields) {
				this.fieldOnBlur(key, updatedFields[key]);
			}
		}
		this.fields[field] = updated;
		return this.fields[field];
	}

	public validateField(field: FormField, isPristine?: boolean): FormField {
		field.errors = {};
		field.errorsArr = [];
		field.valid = true;
		field.touched = isPristine ? false : true;
		const value: any = field.value;

		if (field.mandatory) {
			if (
				(field.numeric && !value && Number(value) !== 0) ||
				// ((!value && value !== 0 && !field.isDate) ||
				(field.isDate && !value) ||
				(value !== 0 && value !== false && !field.isDate && Validators.isStringEmpty(value))
			) {
				field = this.addErr(field, ErrorType.Mandatory, "Mandatory field");
			}
		}

		if ((field.email || field.fieldType === "email") && field.value && !Validators.isValidEmail(value)) {
			field = this.addErr(field, ErrorType.Email, "Invalid email");
		}
		if ((field.numeric || field.fieldType === "number") && value !== undefined && value !== null && isNaN(value)) {
			field = this.addErr(field, ErrorType.Numeric, "Value must be numeric");
		}
		if ((field.numeric || field.fieldType === "number") && field.integer && !Number.isInteger(value)) {
			field = this.addErr(field, ErrorType.Numeric, "Value must be an integer");
		}
		if (field.minLength && (value || "").length < field.minLength) {
			field = this.addErr(field, ErrorType.Minlength, "Minimum length of " + field.minLength);
		}
		if (field.maxLength && (value || "").length > field.maxLength) {
			field = this.addErr(field, ErrorType.Maxlength, "Value exceeds maximum length of " + field.maxLength);
		}
		if (field.compareToField && value !== this.fields[field.compareToField].value) {
			field = this.addErr(field, ErrorType.Compare, "Passwords do not match");
		}
		if (field.isDate && value) {
			const dateValidation = Validators.isValidDateStr(value, field.maxDateToday);
			if (!dateValidation.valid) {
				field = this.addErr(
					field,
					ErrorType.Date,
					`Invalid date ${dateValidation.validRange ? "format" : "selection"}`
				);
			}
		}
		if (field.isUrl && value) {
			const urlValidation = CustomValidators.urlPath(field as DynamicFormField, this, true);
			if (urlValidation.errType) {
				field = this.addErr(field, urlValidation.errType, urlValidation.msg);
			}
		}
		if (field.fieldType === "phone" && !Validators.isValidPhone(value)) {
			field = this.addErr(field, ErrorType.Phone, `Invalid phone`);
		}
		if (field.customValidator && field.valid) {
			const customValidators: CustomValidator[] = Array.isArray(field.customValidator)
				? field.customValidator
				: [field.customValidator];
			for (let i = 0; i < customValidators.length; i++) {
				const validator: CustomValidator = customValidators[i];
				const validationResult: ICustomValidatorResult = validator(field, this);
				console.debug("FormValidation: customValidator result:", validationResult);
				if (validationResult.errType !== undefined) {
					field = this.addErr(field, validationResult.errType, validationResult.msg);
					break;
				}
			}
		}

		return field;
	}

	public async validateFieldAsync(field: FormField, isPristine?: boolean): Promise<FormField> {
		let validatedField = this.validateField(field, isPristine);
		if (validatedField.asyncCustomValidator && validatedField.valid) {
			const validationResult: ICustomValidatorResult = await validatedField.asyncCustomValidator(
				validatedField,
				this
			);
			console.debug("FormValidation: asyncCustomValidator result:", validationResult);
			if (validationResult.errType !== undefined) {
				validatedField = this.addErr(field, validationResult.errType, validationResult.msg);
			}
		}
		return validatedField;
	}

	private addErr(field: FormField, errType: ErrorType, msg: string): FormField {
		console.debug("FormValidation.addErr():", errType);
		field.valid = false;
		field.errors = field.errors || {};
		let err: ValidationError = { errType: errType, message: msg };
		field.errors[ErrorType[errType]] = err;
		field.errorsArr.push(err);
		this.errors[errType] = err;
		return field;
	}

	public validate(): boolean {
		return validateForm(this);
	}

	public async validateWithAsync(): Promise<boolean> {
		return await validateFormAsync(this);
	}

	public getValidatedForm(): FormValidation {
		return getValidatedForm(this);
	}

	public async getValidatedFormWithAsync(): Promise<FormValidation> {
		return await getValidatedFormAsync(this);
	}

	public clear(): FormValidation {
		this.errors = {};
		for (let key in this.fields) {
			this.fields[key].value = undefined;
			this.fields[key].touched = false;
		}
		return this;
	}

	public getErrMsg(field: string, ignoreMandatory?: boolean): string {
		if (!this.fields[field].valid && this.fields[field].errors) {
			const errors = this.fields[field].errors;
			let msg = "";
			for (let err in errors) {
				if (ignoreMandatory && errors[err].errType === ErrorType.Mandatory) {
				} else {
					msg += errors[err].message;
				}
			}
			return msg;
		}
		return "";
	}

	public getCssValidityClass(field: string): string {
		if (!this.fields[field].valid && this.fields[field].errors) return "mss-input-invalid";
		return "";
	}

	public getCssClass(field: string): string {
		return "form-control " + this.getCssValidityClass(field);
	}
}

export function validateForm(form: FormValidation): boolean {
	return getValidatedForm(form).isValid;
}

export function getValidatedForm(form: FormValidation): FormValidation {
	form.errors = {};
	for (let key in form.fields) {
		// let field: FormField = form.fields[key];
		form.fields[key] = form.validateField(form.fields[key], false);
	}
	return form;
}

export async function validateFormAsync(form: FormValidation): Promise<boolean> {
	const validatedForm: FormValidation = await getValidatedFormAsync(form);
	return validatedForm.isValid;
}

export async function getValidatedFormAsync(form: FormValidation): Promise<FormValidation> {
	form.errors = {};
	await asyncForEach(Object.keys(form.fields), async (key: string) => {
		form.fields[key] = await form.validateFieldAsync(form.fields[key], false);
	});
	return form;
}

export const Validators = {
	isStringEmpty: function (value: any): boolean {
		let isWhitespace = (value || "").toString().trim().length === 0;
		return isWhitespace;
	},
	isValidEmail: function (value: string): boolean {
		let regexp = new RegExp(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		return regexp.test(value);
	},
	isValidPhone: function (value: string = ""): boolean {
		var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		if (value.match(phoneno)) return true;
		if (isNaN(value as any)) return false;
		if (value.length === 10) return true;
		return false;
	},
	// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
	isValidDate: function isValidDate(dateString: string): boolean {
		// First check for the pattern
		if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
			return false;
		}
		// Parse the date parts to integers
		var parts = dateString.split("/");
		var day = parseInt(parts[1], 10);
		var month = parseInt(parts[0], 10);
		var year = parseInt(parts[2], 10);

		// Check the ranges of month and year
		if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

		var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

		// Adjust for leap years
		if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

		// Check the range of the day
		return day > 0 && day <= monthLength[month - 1];
	},
	isValidRegexDate: function validateDate(testdate: string): boolean {
		var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
		return date_regex.test(testdate);
	},
	isValidDateStr: function isValidDateStr(value: string, maxDateToday?: boolean) {
		let isValid: boolean;
		let validRange: boolean = true;
		try {
			const d: any = new Date(value);
			if (d === "Invalid Date" || !(d instanceof Date)) {
				isValid = false;
			} else {
				isValid = true;
				if (maxDateToday) {
					const today: Date = new Date();
					if (d > today) {
						isValid = false;
						validRange = false;
					}
				}
			}
		} catch (err) {
			isValid = false;
		}
		return { valid: isValid, validRange: validRange };
	},
};

export const CustomValidators = {
	urlPath: (field: DynamicFormField, form: FormValidation, testFullUrl?: boolean): ICustomValidatorResult => {
		// policyValid = !/\s/g.test(value);
		const regex = /^[a-zA-Z0-9\-_]{0,40}$/;
		let valid: boolean = regex.test(field.value);
		if (testFullUrl) {
			valid = CustomValidators.isValidUrl(field.value);
		}
		if (!valid && !testFullUrl) {
			const strippedSlashes: string = field.value.replace("/", "").replace(":", "").replace("?", "");
			if (regex.test(strippedSlashes)) valid = true;
		}
		if (!valid) {
			return {
				field,
				errType: ErrorType.Invalid,
				msg: testFullUrl ? "Invalid URL" : `${field.label} can't contain spaces or invalid characters.`,
			};
		}
		return {
			field,
		};
	},
	isValidUrl: (url) => {
		try {
			new URL(url);
		} catch (e) {
			console.error(e);
			return false;
		}
		return true;
	},
};
